'use client';

import { sendGTMEvent } from '@next/third-parties/google';
import { FC, useState } from 'react';

import {
  FasThumbsDown,
  FasThumbsUp,
} from '@virginmediao2/momentum-icons/icons/fas';

import { Button } from '@/components/momentum/components/button';
import { Icon } from '@/components/momentum/components/icon';
import { Text } from '@/components/momentum/components/text';
import { VerticalRule } from '@/components/momentum/components/vertical-rule';

import styles from './o2-sc-feedback.module.css';

export enum FeedbackResponseEnum {
  Positive = 'positive',
  Negative = 'negative',
}

interface FeedbackProps {
  responseHeadingText: string;
  feedbackText: string;
  positiveCTALabel: string;
  negativeCTALabel: string;
  pageId?: string;
}

const FeedbackButtons: FC<{
  positiveCTALabel: string;
  negativeCTALabel: string;
  handleSendFeedback: (response: FeedbackResponseEnum) => void;
}> = ({ handleSendFeedback, positiveCTALabel, negativeCTALabel }) => {
  return (
    <div className={styles['feedback__buttons']}>
      <Button
        data-testid={'feedback-button-positive'}
        variant={'primary'}
        decoration={'transparent'}
        onClick={() => handleSendFeedback(FeedbackResponseEnum.Positive)}
        className={styles['feedback__button']}
        icon={<Icon icon={FasThumbsUp} size='md' color={'inherit'} />}
      >
        <Text as={'p'} size={'T300'} weight={'bold'} color={'inherit'}>
          {positiveCTALabel}
        </Text>
      </Button>
      <VerticalRule spacing='sm' />
      <Button
        data-testid={'feedback-button-negative'}
        variant={'primary'}
        decoration={'transparent'}
        onClick={() => handleSendFeedback(FeedbackResponseEnum.Negative)}
        className={styles['feedback__button']}
        icon={<Icon icon={FasThumbsDown} size='md' color={'inherit'} />}
      >
        <Text as={'p'} size={'T300'} weight={'bold'} color={'inherit'}>
          {negativeCTALabel}
        </Text>
      </Button>
    </div>
  );
};

export const Feedback: FC<FeedbackProps> = ({
  responseHeadingText,
  feedbackText,
  positiveCTALabel,
  negativeCTALabel,
  pageId,
}) => {
  const [feedbackSent, setFeedbackSent] = useState(false);

  const handleSendFeedback = (responseType: FeedbackResponseEnum) => {
    setFeedbackSent(true);
    const eventName = `${pageId}/${feedbackText}`;
    sendGTMEvent({ event: eventName, value: responseType });
  };
  return (
    <div className={styles['feedback']} data-testid={'feedback-component'}>
      <Text as={'p'} size={'T400'} weight={'bold'}>
        {feedbackText}
      </Text>
      {!feedbackSent ? (
        <FeedbackButtons
          handleSendFeedback={handleSendFeedback}
          positiveCTALabel={positiveCTALabel}
          negativeCTALabel={negativeCTALabel}
        />
      ) : (
        <div
          className={styles['feedback__response']}
          data-testid={'feedback-response'}
        >
          <Text as={'p'} size={'T400'} weight={'regular'}>
            {responseHeadingText}
          </Text>
        </div>
      )}
    </div>
  );
};
