import clsx from 'clsx';

import { ReactNode } from 'react';

import DynamoImageLink from '@/components/storyblok/bloks/dynamo-image-link';
import { DynamoImageLinkBlok } from '@/components/storyblok/bloks/dynamo-image-link/dynamo-image-link.blok';
import { DynamoLinkBlok } from '@/components/storyblok/bloks/dynamo-link';
import { DynamoNavigation } from '@/components/storyblok/types/dynamo-navigation';

import styles from './l1-navigation-item.module.scss';

export interface L1NavigationItemProps {
  item: DynamoNavigation | DynamoLinkBlok | DynamoImageLinkBlok;
  children: ReactNode;
}

export function L1NavigationItem({ item, children }: L1NavigationItemProps) {
  return (
    <li className={clsx(styles['mega-menu-navigation-item'])} data-dropdown>
      {children}
    </li>
  );
}
