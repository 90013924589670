import { FC, ReactNode } from 'react';

import { Section } from '@/components/momentum/components/section';

import styles from './l2-navigation.module.scss';

export interface L2NavigationProps {
  children: ReactNode;
}

export const L2Navigation: FC<L2NavigationProps> = ({ children }) => (
  <Section variant={'primary'} layout={'spacious'} verticalRhythm={'sm'}>
    <nav className={styles['mega-menu-navigation-category']} tabIndex={-1}>
      {children}
    </nav>
  </Section>
);
