import clsx from 'clsx';

import { ComponentProps, FC } from 'react';

import {
  FasCircleCheck,
  FasCircleExclamation,
  FasCircleInfo,
  FasTriangleExclamation,
} from '@virginmediao2/momentum-icons/icons/fas';

import { Icon } from '@/components/momentum/components/icon';
import { Text } from '@/components/momentum/components/text';

import styles from './pill.module.scss';

export interface PillProps extends ComponentProps<'div'> {
  title: string;
  variant?: 'info' | 'success' | 'warning' | 'error';
  outline?: boolean;
  showIcon?: boolean;
}

export const getIcon = (variant: PillProps['variant']) => {
  switch (variant) {
    case 'error':
      return <Icon icon={FasTriangleExclamation} size='md' color={'inherit'} />;
    case 'success':
      return <Icon icon={FasCircleCheck} size='md' color={'inherit'} />;
    case 'warning':
      return <Icon icon={FasCircleExclamation} size='md' color={'inherit'} />;
    default:
      return <Icon icon={FasCircleInfo} size='md' color={'inherit'} />;
  }
};

export const Pill: FC<PillProps> = ({
  title,
  outline,
  variant = 'warning',
  className,
  showIcon,
  ...props
}) => {
  return (
    <div
      className={clsx(
        {
          [styles.pill]: true,
          [styles['pill--outline']]: !!outline,
          [styles['pill--info']]: variant === 'info',
          [styles['pill--success']]: variant === 'success',
          [styles['pill--warning']]: variant === 'warning',
          [styles['pill--error']]: variant === 'error',
          [styles['pill--sizer']]: !showIcon,
        },
        className
      )}
      data-testid={'pill'}
      {...props}
    >
      {showIcon && (
        <div className={styles['pill__icon']} data-testid={'pill-icon'}>
          {getIcon(variant)}
        </div>
      )}
      <Text
        className={styles['pill__text']}
        data-testid={'pill-text'}
        size={'T200'}
        weight={'bold'}
        color={'inherit'}
      >
        {title}
      </Text>
    </div>
  );
};
