import clsx from 'clsx';

import { FC, ReactNode } from 'react';

import { Section } from '@/components/momentum/components/section';

import MegaMenuFunctions from './mega-menu-functions';
import './megamenu-global.scss';
import styles from './megamenu.module.scss';

export interface MegamenuProps {
  prefix?: ReactNode;
  navigation?: ReactNode;
  sideNavigation?: ReactNode;
  utils?: ReactNode;
}

export const Megamenu: FC<MegamenuProps> = ({
  prefix,
  navigation,
  sideNavigation,
  utils,
  ...props
}) => (
  <div className={styles['mega-menu']} {...props}>
    <Section
      variant={'transparent'}
      layout={'spacious'}
      verticalRhythm={'none'}
    >
      <div className={styles['mega-menu__menu-container']}>
        {prefix && <div className={styles['mega-menu__prefix']}>{prefix}</div>}
        {navigation && (
          <div className={styles['mega-menu__nav']} data-megamenu-nav>
            {navigation}
          </div>
        )}
        {sideNavigation && (
          <div
            className={clsx(styles['mega-menu__sidenav'], 'fade-in')}
            data-sidenav
          >
            {sideNavigation}
          </div>
        )}
        {utils && <div className={styles['mega-menu__utils']}>{utils}</div>}
      </div>
    </Section>
    <MegaMenuFunctions />
  </div>
);
