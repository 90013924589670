import { z } from 'zod';

import { Alert } from '@virginmediao2/momentum-nextjs/alert';
import { Tab, Tabs } from '@virginmediao2/momentum-nextjs/tabs';
import { UseFormReturn } from 'react-hook-form';

import { DeviceEntry } from '@/lib/imei-checker/parseCsv';

const requiredErrorMessage = 'Please fill out this required value.';

const eightCharactherErrorMessage = `Not enough digits have been entered. Please check and try again.`;

export interface DeviceCheckerFormProps {
  url?: string;
}

export const deviceNameSchema = z.object({
  deviceName: z.string().min(1, requiredErrorMessage),
});

export const deviceModelNumberSchema = z.object({
  deviceModelNumber: z.string().min(1, requiredErrorMessage),
});

export type DeviceModelNumberSchemaKeys = keyof z.infer<
  typeof deviceModelNumberSchema
>;

export type ImeiNumberSchemaKeys = keyof z.infer<typeof imeiNumberSchema>;

export type DeviceNameSchemaKeys = keyof z.infer<typeof deviceNameSchema>;

export const imeiNumberSchema = z.object({
  imeiNumber: z
    .string()
    .min(1, requiredErrorMessage)
    .regex(/^\d{8}$/, eightCharactherErrorMessage),
});

type ConstructAlertPayload =
  | {
      message: string;
      status: 'success' | 'error';
    }
  | undefined;

export const constructAlert = (
  selectedDevice: DeviceEntry | null | undefined
): ConstructAlertPayload => {
  if (!selectedDevice) {
    return;
  }

  if (selectedDevice['VoLTE Capable'].trim() !== 'Yes') {
    return {
      message: `Your device is an ${selectedDevice['Device Description'].trim()}. It is not volt capable.`,
      status: 'error',
    };
  }

  return {
    message: `Your device is an ${selectedDevice['Device Description'].trim()}. This is compatible with 4G calling abroad.`,
    status: 'success',
  };
};

export const Notifications = ({
  selectedDevice,
  form,
  actionStatus,
  setSelectedDevice,
}: {
  selectedDevice: DeviceEntry | null;
  form: UseFormReturn;
  actionStatus: { payload?: DeviceEntry[] };
  setSelectedDevice: (entry: DeviceEntry) => void;
}) => {
  const derivedData = constructAlert(selectedDevice);

  return (
    <>
      {actionStatus.payload?.length === 0 && form.formState.isSubmitted && (
        <Alert title='No devices found.' variant='error' />
      )}
      {Boolean(actionStatus.payload?.length) && (
        <Tabs
          controls={(actionStatus.payload?.length ?? 0) > 2}
          breakout={(actionStatus.payload?.length ?? 0) > 2}
        >
          {actionStatus.payload?.map((result) => (
            <Tab
              key={result['Device Description']}
              active={
                selectedDevice?.['Device Description'] ===
                result['Device Description']
              }
              onClick={() => {
                setSelectedDevice(result);
              }}
            >
              {result['Device Description']}
            </Tab>
          ))}
        </Tabs>
      )}
      {derivedData?.status === 'success' && (
        <Alert title='Great news!' variant='success'>
          {derivedData.message}
        </Alert>
      )}
      {derivedData?.status === 'error' && (
        <Alert
          title='Unfortunately this device is not volt capable'
          variant='error'
        >
          {derivedData.message}
        </Alert>
      )}
    </>
  );
};
