import { FC } from 'react';

import {
  L2Navigation,
  L2NavigationItem,
} from '@/components/momentum/factory/mega-menu';
import { L3Category } from '@/components/storyblok/bloks/dynamo-megamenu/dropdown/l3-category';
import { DynamoNavigation } from '@/components/storyblok/types/dynamo-navigation';

export interface L2CategoryProps {
  category: DynamoNavigation;
}

export const L2Category: FC<L2CategoryProps> = ({ category }) => {
  const links = category.links.filter(
    (item) => item.component === '_dynamo_navigation'
  ) as Array<DynamoNavigation>;

  return (
    <L2Navigation>
      {links.map((item) => (
        <L2NavigationItem key={item._uid} title={item.title}>
          <L3Category category={item} />
        </L2NavigationItem>
      ))}
    </L2Navigation>
  );
};
