import { FC, ReactNode } from 'react';

import { Text } from '@/components/momentum/components/text';

export interface L2NavigationItemProps {
  title?: string;
  children: ReactNode;
}

export const L2NavigationItem: FC<L2NavigationItemProps> = ({
  title,
  children,
}) => (
  <div>
    {title && (
      <Text weight={'medium'} size={'T200'} marginBottom>
        {title}
      </Text>
    )}
    {children}
  </div>
);
