'use client';

import clsx from 'clsx';

import type { ComponentProps, FC, ReactNode } from 'react';

import styles from './grid.module.css';

export interface GridProps extends Omit<ComponentProps<'div'>, 'className'> {
  children: ReactNode;
  sm: '1' | '2';
  md: '1' | '2' | '3' | '4' | '5';
  lg: '1' | '2' | '3' | '4' | '5' | '6';
  justifyItems?: 'start' | 'center' | 'end' | 'stretch' | 'left' | 'right';
  alignContent?: 'start' | 'center' | 'end';
  height?: 'auto' | 'full' | 'screen';
  'data-testid'?: string;
}

export const Grid: FC<GridProps> = ({
  sm,
  md,
  lg,
  justifyItems,
  alignContent,
  height,
  children,
  ...props
}) => (
  <div
    className={clsx({
      [styles['grid']]: true,
      [styles['grid--columns-sm-1']]: sm === '1',
      [styles['grid--columns-sm-2']]: sm === '2',
      [styles['grid--columns-md-1']]: md === '1',
      [styles['grid--columns-md-2']]: md === '2',
      [styles['grid--columns-md-3']]: md === '3',
      [styles['grid--columns-md-4']]: md === '4',
      [styles['grid--columns-md-5']]: md === '5',
      [styles['grid--columns-lg-1']]: lg === '1',
      [styles['grid--columns-lg-2']]: lg === '2',
      [styles['grid--columns-lg-3']]: lg === '3',
      [styles['grid--columns-lg-4']]: lg === '4',
      [styles['grid--columns-lg-5']]: lg === '5',
      [styles['grid--columns-lg-6']]: lg === '6',
      [styles[`grid--justify-items-${justifyItems}`]]: !!justifyItems,
      [styles[`grid--align-content-${alignContent}`]]: !!alignContent,
      [styles[`grid--height-${height}`]]: !!height,
    })}
    {...props}
  >
    {children}
  </div>
);
