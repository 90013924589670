import { FC } from 'react';

import { DatapointProps } from '../interfaces';
import { Datapoint4G, Datapoint5G, UkDialling } from './Datapoints';

export const SelectDatapoint: FC<DatapointProps> = ({ datapoint }) => {
  switch (datapoint) {
    case '4G':
      return <Datapoint4G />;
    case '5G':
      return <Datapoint5G />;
    case 'UK_DIALLING':
      return <UkDialling />;
  }
};
