'use client';

import clsx from 'clsx';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {
  Autoplay,
  EffectCoverflow,
  EffectFade,
  Navigation,
  Pagination,
} from 'swiper/modules';

import Image from 'next/image';
import { FC, ReactNode } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './carousel.module.scss';
import { CarouselProps } from './types';

export const Carousel: FC<CarouselProps> = ({
  slides,
  isPagination,
  isNavigation,
  theme,
  height,
  loop,
  autoplay,
  effect,
  children,
}) => {
  const pagination = () => {
    const styleConfig = clsx({
      [styles[`carousel__pagination-bullet--white`]]: theme === 'white',
    });
    return {
      enabled: isPagination,
      clickable: true,
      horizontalClass: clsx({
        [styles[`carousel__pagination`]]: true,
      }),
      bulletClass: `swiper-pagination-bullet ${styleConfig}`,
    };
  };

  return (
    <Swiper
      modules={[EffectFade, EffectCoverflow, Autoplay, Navigation, Pagination]}
      pagination={pagination()}
      navigation={isNavigation}
      autoplay={autoplay}
      loop={loop}
      effect={effect}
      fadeEffect={{
        crossFade: effect === 'fade',
      }}
      wrapperClass={clsx({ [styles[`carousel__wrapper--${height}`]]: true })}
      className={clsx({
        [styles[`carousel`]]: true,
        [styles[`carousel--${theme}`]]: theme,
      })}
    >
      {children &&
        children.map((slide: ReactNode, index: number) => (
          <SwiperSlide
            key={index}
            className={clsx({
              [styles['carousel__background']]: true,
              [styles[
                `carousel__background--${slides[index].background_color}`
              ]]: slides[index].background_color,
            })}
          >
            <div
              className={clsx({
                [styles['carousel__content']]: true,
                [styles[
                  `carousel__content--${slides[index].vertical_align_content}`
                ]]: slides[index].vertical_align_content,
              })}
            >
              {slide}
            </div>
            {slides[index].background_image?.filename && (
              <Image
                src={slides[index].background_image?.filename}
                alt={slides[index].background_image?.alt || ''}
                className={clsx({
                  [styles[
                    `carousel__background-image--${slides[index].background_image_size}`
                  ]]: slides[index].background_image_size,
                })}
                fill
              />
            )}
          </SwiperSlide>
        ))}
    </Swiper>
  );
};
