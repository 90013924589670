import { ISbLinkURLObject } from 'storyblok-js-client';

import { type ISbRichtext } from '@storyblok/react/rsc';
import Link from 'next/link';
import { FC, ReactNode, memo } from 'react';
import type { RenderOptions } from 'storyblok-rich-text-react-renderer';
import { render } from 'storyblok-rich-text-react-renderer';

import { IStoryblokComponent } from '@/components/storyblok/renderer';

interface RichTextProps {
  document: ISbRichtext;
  StoryblokComponent: IStoryblokComponent;
}

/**
 * Reduce attributes, overlaying story.url onto href.
 * @FIXES VK-534 - Storyblok bug where link URL is not updated when story is moved.
 */
export const mapAttributes = (attrs: Record<string, unknown>) =>
  Object.entries(attrs)
    .filter(([key]) => key !== 'linktype')
    .reduce((acc, [key, value]) => {
      if (key === 'story') {
        const story = value as ISbLinkURLObject;

        if (!story?.full_slug) {
          return acc;
        }

        // Remove CMS specific segment from story.url (e.g. /o2-co-uk/ -> /)
        const strippedUrl = story.full_slug.replace('/o2-co-uk/', '/');

        return { ...acc, href: `${strippedUrl}` };
      } else if (key === 'href') {
        const href = value as string;

        if ('href' in acc) {
          return acc;
        }

        return { ...acc, href };
      }

      return { ...acc, [key]: value };
    }, {});

const renderOptions = (StoryblokComponent: IStoryblokComponent) => ({
  // Handle rendering of component bloks
  defaultBlokResolver: (
    name: string,
    props: Record<string, unknown> & { _uid: string }
  ) => {
    const blok = { ...props, component: name };
    return <StoryblokComponent blok={blok} key={props._uid} />;
  },
  markResolvers: {
    // Provide custom resolver to handle story links
    link: (children: ReactNode, attrs: Record<string, unknown>) => (
      <Link href={attrs.href ?? ''} {...mapAttributes(attrs)}>
        {children}
      </Link>
    ),
  },
});

export const RenderRichText = memo<RichTextProps>(
  ({ document, StoryblokComponent }) =>
    render(document, renderOptions(StoryblokComponent))
);

RenderRichText.displayName = 'RenderRichText';
