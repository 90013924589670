import clsx from 'clsx';

import type { CSSProperties, FC } from 'react';

import type { Icon as SvgIcon } from '@virginmediao2/momentum-icons';

import styles from './icon.module.scss';
import type { StoryblokIcon } from './theme-icons.interface';
import { useIcon } from './use-icon.hook';
import { useSvg } from './use-svg.hook';

export interface IconProps {
  icon: SvgIcon | StoryblokIcon;
  ratio?: 1 | 0.75 | 0.5;
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | 'inherit';
  color?:
    | 'success'
    | 'error'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark'
    | 'inherit';
}

export const Icon: FC<IconProps> = ({
  icon,
  size = 'md',
  ratio = 0.75,
  color,
  ...props
}) => {
  if (!icon) {
    throw new Error('Icon is undefined');
  }

  const iconObject = useIcon(icon);
  const svg = useSvg(iconObject);

  return (
    <span
      className={clsx({
        [styles['icon']]: true,
        [styles['icon--ratio-1']]: ratio === 1,
        [styles['icon--ratio-075']]: ratio === 0.75,
        [styles['icon--ratio-05']]: ratio === 0.5,
        [styles['icon--size-sm']]: size === 'sm',
        [styles['icon--size-md']]: size === 'md',
        [styles['icon--size-lg']]: size === 'lg',
        [styles['icon--size-xl']]: size === 'xl',
        [styles['icon--size-2xl']]: size === '2xl',
        [styles['icon--size-3xl']]: size === '3xl',
        [styles['icon--size-inherit']]: size === 'inherit',
        [styles['icon--color-success']]: color === 'success',
        [styles['icon--color-error']]: color === 'error',
        [styles['icon--color-warning']]: color === 'warning',
        [styles['icon--color-info']]: color === 'info',
        [styles['icon--color-light']]: color === 'light',
        [styles['icon--color-dark']]: color === 'dark',
        [styles['icon--color-inherit']]: color === 'inherit',
      })}
      role={'img'}
      {...props}
    >
      {svg && <span className={styles['icon__host']}>{svg}</span>}

      {iconObject.url && (
        <span className={styles['icon__host']}>
          <span
            className={styles['icon__mask']}
            style={{ '--_maskUrl': iconObject.url } as CSSProperties}
          />
        </span>
      )}
    </span>
  );
};
