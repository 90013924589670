import { FC, ReactNode } from 'react';

import styles from './megamenu-prefix.module.scss';

export interface MegamenuPrefixProps {
  children: ReactNode;
}

export const MegamenuPrefix: FC<MegamenuPrefixProps> = ({ children }) => (
  <div className={styles['mega-menu-prefix']}>{children}</div>
);
