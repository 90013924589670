import { FC } from 'react';

import {
  O2Line4G,
  O2Line5G,
  O2LineMobileCoverage,
} from '@virginmediao2/momentum-icons/icons/o2-line';

import { Icon } from '@/components/momentum/components/icon';
import { Text } from '@/components/momentum/components/text';

import styles from './CountryRates.module.css';

export const Datapoint4G: FC = () => (
  <div className={styles['country__datapoint']}>
    <Icon color='info' icon={O2Line4G} size={'lg'} />
    <Text size='T200' color={'default'}>
      data is available here
    </Text>
  </div>
);
export const Datapoint5G: FC = () => (
  <div className={styles['country__datapoint']}>
    <Icon color='info' icon={O2Line5G} size={'lg'} />
    <Text size='T200' color={'default'}>
      data is available here
    </Text>
  </div>
);

export const UkDialling: FC = () => (
  <div className={styles['country__datapoint']}>
    <Icon color='info' icon={O2LineMobileCoverage} size={'lg'} />
    <Text size='T200' color={'default'} alignment={'center'}>
      UK dialling – no need to use +44
    </Text>
  </div>
);
