'use client';

import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { type ComponentProps, type FC, useCallback } from 'react';

import { FilterChip } from '../filter-chip/filter-chip';
import { Rail } from '../rail';

export type Chip = {
  label: string;
  value: string;
  linkUrl?: string;
  filterTags?: string[];
  params?: URLSearchParams;
  multiselect?: boolean;
  pathname?: string;
  router?: AppRouterInstance;
  disabled: boolean;
};

export interface FilterChipsProps
  extends Omit<ComponentProps<'div'>, 'className'> {
  chips: Chip[];
  multiselect: boolean;
}

export const FilterChips: FC<FilterChipsProps> = ({ chips, multiselect }) => {
  const pathname = usePathname();
  const router = useRouter();
  const searchParams = useSearchParams();
  const params = new URLSearchParams(searchParams);
  const filterTags = (params.get('filterTags')?.split(',') || []).filter(
    (tag) => tag
  );

  const handleFilterClick = useCallback(
    (
      value: string,
      linkUrl: string | undefined,
      filterTags: string[],
      params: URLSearchParams,
      multiselect: boolean,
      pathname: string,
      router: AppRouterInstance
    ) => {
      if (linkUrl) {
        router.push(linkUrl);
        return;
      }

      const updatedTags = filterTags.includes(value)
        ? filterTags.filter((tag) => multiselect && tag !== value)
        : [...(multiselect ? filterTags : []), value];
      params.set('filterTags', updatedTags.join(','));

      router.replace(`${pathname}?${params.toString()}`);
    },
    []
  );

  return (
    <Rail.Root>
      <Rail.Scroller data-testid='filter-chips'>
        {chips.map(({ label, value, linkUrl, disabled }) => {
          return (
            <FilterChip
              key={label}
              label={label}
              value={value}
              linkUrl={linkUrl}
              filterTags={filterTags}
              params={params}
              multiselect={multiselect}
              pathname={pathname}
              router={router}
              isActive={filterTags.includes(value)}
              onFilterClick={handleFilterClick}
              disabled={disabled}
            />
          );
        })}
      </Rail.Scroller>
    </Rail.Root>
  );
};
