'use client';

import clsx from 'clsx';

import type { ComponentProps, ReactNode } from 'react';
import { forwardRef } from 'react';

import styles from './button.module.scss';
import type { _ButtonProps } from './button.props';

export interface ButtonProps extends _ButtonProps, ComponentProps<'button'> {
  decoration?: 'solid' | 'outline' | 'transparent';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'destructive';
  layout?: 'full-width' | 'default';
  icon?: ReactNode;
  iconOnly?: boolean;
  iconPosition?: 'start' | 'end';
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      decoration,
      variant,
      type = 'button',
      layout,
      icon,
      iconOnly,
      iconPosition,
      className,
      ...props
    },
    ref
  ) => (
    <button
      type={type}
      ref={ref}
      className={clsx(
        {
          [styles.button]: true,
          [styles['button--primary']]: variant === 'primary',
          [styles['button--secondary']]: variant === 'secondary',
          [styles['button--tertiary']]: variant === 'tertiary',
          [styles['button--destructive']]: variant === 'destructive',
          [styles['button--solid']]: decoration === 'solid',
          [styles['button--outline']]: decoration === 'outline',
          [styles['button--transparent']]: decoration === 'transparent',
          [styles['button--layout-full-width']]: layout === 'full-width',
          [styles['button--icon']]: !!icon,
          [styles['button--icon-only']]: iconOnly,
          [styles['button--icon-start']]: iconPosition === 'start',
          [styles['button--icon-end']]: iconPosition === 'end',
        },
        className
      )}
      {...props}
    >
      {icon && <span className={styles['button__icon']}>{icon}</span>}
      <span className={styles['button__text']}>{children}</span>
    </button>
  )
);

Button.displayName = 'Button';
