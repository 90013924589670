import React, { ComponentProps } from 'react';

import styles from './form-item.module.scss';

export interface FormItemErrorProps
  extends Omit<ComponentProps<'div'>, 'id' | 'className'> {
  id: string;
  children: React.ReactNode;
}

export const FormItemError: React.FC<FormItemErrorProps> = ({
  id,
  children,
  ...props
}) => {
  return (
    <div id={id} className={styles['form-item__error']} {...props}>
      {children}
    </div>
  );
};
