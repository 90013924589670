'use client';

import { SearchResult } from 'minisearch';

import { FC, useEffect, useState } from 'react';

import { getCountryCharges } from '@/components/mini-apps/intl-charges/actions';
import { Section } from '@/components/momentum/components/section';
import { IStoryblokComponent } from '@/components/storyblok/renderer';

import { CountryRates, SearchBox, SearchResults } from './components';
import { IntlCallRatesContent } from './types';

export interface InternationalChargesProps {
  StoryblokComponent?: IStoryblokComponent;
}

export const InternationalCharges: FC<InternationalChargesProps> = ({
  StoryblokComponent,
}) => {
  const [searchResults, setSearchResults] = useState<Array<SearchResult>>();
  const [selectedCountry, setSelectedCountry] = useState<SearchResult>();
  const [selectedData, setSelectedData] = useState<IntlCallRatesContent>();

  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      setSelectedCountry(searchResults[0]);
    }
  }, [searchResults]);

  useEffect(() => {
    selectedCountry
      ? getCountryCharges(selectedCountry.id).then(setSelectedData)
      : setSelectedData(undefined);
  }, [selectedCountry]);

  return (
    <>
      <Section
        verticalRhythm={'md'}
        layout={'spacious'}
        reducedSpacing={'bottom'}
      >
        <SearchBox
          setSelectedCountry={setSelectedCountry}
          setSelectedData={setSelectedData}
          setSearchResults={setSearchResults}
        />
      </Section>

      {searchResults && (
        <Section
          verticalRhythm={'md'}
          layout={'spacious'}
          reducedSpacing={'bottom'}
        >
          <SearchResults
            searchResults={searchResults}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
          />
        </Section>
      )}

      {selectedData && (
        <Section
          verticalRhythm={'md'}
          layout={'spacious'}
          reducedSpacing={'bottom'}
        >
          <CountryRates
            blok={selectedData.content}
            StoryblokComponent={StoryblokComponent!}
          />
        </Section>
      )}
    </>
  );
};
