import clsx from 'clsx';

import { ComponentProps, FC } from 'react';

import {
  FasCircleCheck,
  FasCircleExclamation,
  FasCircleInfo,
  FasTriangleExclamation,
} from '@virginmediao2/momentum-icons/icons/fas';

import { Icon } from '@/components/momentum/components/icon';
import { Text } from '@/components/momentum/components/text';

import styles from './alert.module.scss';

export interface AlertProps extends ComponentProps<'div'> {
  title?: string;
  variant?: 'info' | 'success' | 'warning' | 'error';
  bright?: boolean;
  children?: React.ReactNode;
}

export const getIcon = (variant: AlertProps['variant']) => {
  switch (variant) {
    case 'error':
      return <Icon icon={FasTriangleExclamation} size='md' color={'error'} />;
    case 'success':
      return <Icon icon={FasCircleCheck} size='md' color={'success'} />;
    case 'warning':
      return <Icon icon={FasCircleExclamation} size='md' color={'warning'} />;
    default:
      return <Icon icon={FasCircleInfo} size='md' color={'info'} />;
  }
};

export const Alert: FC<AlertProps> = ({
  title,
  variant = 'warning',
  bright,
  className,
  children,
  ...props
}) => (
  <div
    className={clsx(
      {
        [styles.alert]: true,
        [styles['alert--background-bright']]: !!bright,
        [styles['alert--has-head']]: !!title,
        [styles['alert--info']]: variant === 'info',
        [styles['alert--success']]: variant === 'success',
        [styles['alert--warning']]: variant === 'warning',
        [styles['alert--error']]: variant === 'error',
      },
      className
    )}
    data-testid={'alert'}
    {...props}
  >
    <div className={styles['alert__icon']} data-testid={'alert-icon'}>
      {getIcon(variant)}
    </div>
    {title && (
      <div className={styles['alert__head']} data-testid={'alert-title'}>
        <Text as={'h2'} size={'T300'} weight={'bold'}>
          {title}
        </Text>
      </div>
    )}
    <div className={styles['alert__body']} data-testid={'alert-body'}>
      {children}
    </div>
  </div>
);
