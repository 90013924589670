'use client';

import { useEffect, useRef } from 'react';

export default function MegaMenuFunctions(): null {
  const initialized = useRef(false);
  const megaFunction = (): void => {
    const megamenuNav = document.querySelector('[data-megamenu-nav]');

    if (megamenuNav) {
      const items = megamenuNav.querySelectorAll('[data-dropdown]');
      let currentlyOpen: Element | null = null;
      let closeTimeout: number | null = null;

      const cancelClose = (): void => {
        if (closeTimeout) {
          clearTimeout(closeTimeout);
          closeTimeout = null;
        }
      };

      const hideContent = (content: Element, item: Element): void => {
        content.classList.add('hidden');

        (content as any).timeout = setTimeout(() => {
          if (content) {
            (content as HTMLElement).style.display = 'none';
            content.classList.remove('hidden');
          }
          item.classList.remove('mega-menu-navigation-item--expanded');
          if (currentlyOpen === content) {
            currentlyOpen = null;
          }
        }, 300);
      };

      items.forEach((item) => {
        const trigger = item.querySelector('[data-category-trigger]');
        const content = item.querySelector('[data-category-content]');

        if (trigger && content) {
          content.classList.add('fade-out');
          (content as HTMLElement).style.display = 'none';

          const showContent = (): void => {
            cancelClose();
            items.forEach((i) =>
              i.classList.remove('mega-menu-navigation-item--expanded')
            );

            item.classList.add('mega-menu-navigation-item--expanded');

            if (currentlyOpen && currentlyOpen !== content) {
              (currentlyOpen as HTMLElement).style.display = 'none';
              currentlyOpen.classList.remove('hidden');
            }

            (content as HTMLElement).style.display = 'block';
            content.classList.remove('hidden');
            currentlyOpen = content;
          };

          trigger.addEventListener('mouseenter', showContent);
          content.addEventListener('mouseenter', showContent);

          trigger.addEventListener('mouseleave', (event: Event) => {
            if (
              !content.contains((event as MouseEvent).relatedTarget as Node)
            ) {
              closeTimeout = window.setTimeout(
                () => hideContent(content, item),
                100
              );
            }
          });

          content.addEventListener('mouseleave', (event: Event) => {
            if (
              !trigger.contains((event as MouseEvent).relatedTarget as Node) &&
              !((event as MouseEvent).relatedTarget as Element).closest(
                '[data-category-trigger]'
              )
            ) {
              closeTimeout = window.setTimeout(
                () => hideContent(content, item),
                100
              );
            }
          });
        }
      });

      megamenuNav.addEventListener(
        'mouseenter',
        (event: Event) => {
          if (
            (event.target as Element).closest(
              '[data-category-trigger], [data-category-content]'
            )
          ) {
            cancelClose();
          }
        },
        true
      );

      // Add Escape key functionality
      document.addEventListener('keydown', (event: KeyboardEvent) => {
        if (event.key === 'Escape' && currentlyOpen) {
          const item = currentlyOpen.closest('[data-dropdown]');
          if (item) {
            hideContent(currentlyOpen, item);
            const trigger = item.querySelector(
              '[data-category-trigger]'
            ) as HTMLElement;
            if (trigger) {
              trigger.focus();
            }
          }
        }
      });
    }

    const sideNav = document.querySelector('[data-sidenav]');
    const sideNavTrigger = document.querySelector('[data-sidenav-trigger]');

    if (sideNav && sideNavTrigger) {
      const handleSideNavToggle = (event: Event) => {
        event.preventDefault();
        event.stopPropagation();
        const isVisible = sideNav.classList.toggle('visible');

        window.dispatchEvent(
          new CustomEvent('sidenavvisibilitychange', { detail: { isVisible } })
        );
      };

      sideNavTrigger.addEventListener('click', handleSideNavToggle);

      sideNav.classList.add('fade-in');
    }
  };

  useEffect(() => {
    if (initialized.current) return;
    initialized.current = true;
    megaFunction();

    return () => {
      document.removeEventListener('keydown', () => {});
      const sideNavTrigger = document.querySelector('[data-sidenav-trigger]');
      if (sideNavTrigger) {
        sideNavTrigger.removeEventListener('click', () => {});
      }
    };
  }, []);

  return null;
}
