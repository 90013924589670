import clsx from 'clsx';

import React, { ComponentProps, forwardRef } from 'react';

import { FormItem } from '../form-item';
import { FormItemProps } from '../form-item/types';
import { useFieldIds } from '../hooks/useFieldIds';
import { SharedFormFieldProps } from '../types/types';
import styles from './form-input.module.scss';

export interface FormInputProps
  extends Omit<
    ComponentProps<'input'>,
    'id' | 'name' | 'inputMode' | 'type' | 'placeholder'
  > {
  inputMode?: React.InputHTMLAttributes<HTMLInputElement>['inputMode'];
  type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
  autocomplete?: string;
  placeholder?: string;
  minLength?: number;
  maxLength?: number;
  affix?: string;
  customWidth?: '2ch' | '4ch';
}

export const FormInput = forwardRef<
  HTMLInputElement,
  FormInputProps & FormItemProps & SharedFormFieldProps
>(
  (
    {
      id,
      name,
      dataTestId,
      inputMode,
      autocomplete,
      placeholder,
      disabled = false,
      minLength,
      maxLength,
      readonly = false,
      required = false,
      type = 'text',
      affix,
      customWidth,
      hasDescription = false,
      label,
      description,
      error,
      hideLabels,
      marginBottom,
      ...props
    },
    ref
  ) => {
    const isError = !!error;
    const fieldIds = useFieldIds({
      id,
      isError: !!isError,
      hasDescription,
    });

    return (
      <FormItem
        fieldIds={fieldIds}
        label={label}
        error={error}
        description={description}
        hideLabels={hideLabels}
        marginBottom={marginBottom}
      >
        <div
          className={clsx(styles['form-input'], {
            [styles['form-input--disabled']]: disabled,
            [styles['form-input--readonly']]: readonly,
            [styles['form-input--error']]: isError,
            [styles['form-input--width-2ch']]: customWidth === '2ch',
            [styles['form-input--width-4ch']]: customWidth === '4ch',
          })}
          data-testid={`form-input--${fieldIds.id}`}
        >
          {affix && (
            <div
              className={clsx(
                styles['form-input__affix'],
                styles['form-input__affix--start']
              )}
            >
              {affix}
            </div>
          )}
          <input
            id={id}
            name={name}
            aria-describedby={fieldIds.describedBy}
            aria-invalid={!!isError ? 'true' : undefined}
            aria-disabled={!!disabled ? 'true' : undefined}
            aria-required={!!required ? 'true' : undefined}
            aria-readonly={!!readonly ? 'true' : undefined}
            readOnly={!!readonly}
            disabled={disabled}
            ref={ref}
            className={clsx(styles['form-input__control'], {
              [styles['form-input--invalid']]: isError,
              [styles[`form-input__control--affix--start--${affix?.length}ch`]]:
                affix,
            })}
            inputMode={inputMode}
            autoComplete={autocomplete}
            minLength={minLength}
            maxLength={maxLength}
            data-testid={
              dataTestId ? dataTestId : `form-input-field--${fieldIds.id}`
            }
            type={type}
            placeholder={placeholder}
            {...props}
          />
        </div>
      </FormItem>
    );
  }
);

FormInput.displayName = 'FormInput';
export default FormInput;
