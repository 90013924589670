'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';

import { Anchor } from '@virginmediao2/momentum-nextjs/anchor';
import { Button } from '@virginmediao2/momentum-nextjs/button';
import { FlexBlock } from '@virginmediao2/momentum-nextjs/flex-block';
import {
  FormInput,
  FormRadioGroup,
  FormRadioInput,
} from '@virginmediao2/momentum-nextjs/forms';
import { Text } from '@virginmediao2/momentum-nextjs/text';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Alert } from '@/components/momentum/components/alert';
import { HorizontalRule } from '@/components/momentum/components/horizontal-rule';
import { StepIndicator } from '@/components/momentum/components/step-indicator';
import { VerticalRhythm } from '@/components/momentum/components/vertical-rhythm';

import styles from './rpi.module.scss';
import {
  RpiForm,
  RpiFormStep,
  billType,
  productType,
  rpiSchema,
} from './schema';
import {
  calculateTariffIncrease,
  getAlertLabel,
  getCalculationText,
  getFormattedTermDates,
  getStepLabel,
} from './utils';

export interface RPIProps {
  rpi?: string;
  increaseRate?: string;
  termStart?: string;
  termEnd?: string;
}

export const RpiApp: FC<RPIProps> = (props) => {
  const rpi = Number.parseFloat(props.rpi ?? '4.9');

  const { end } = getFormattedTermDates({
    end: props.termEnd,
    start: props.termStart,
  });

  const increaseRate = Number.parseFloat(props.increaseRate ?? '3.9');

  const form = useForm<RpiForm>({
    resolver: zodResolver(rpiSchema),
    defaultValues: {
      bill: {
        planType: 'SIM Only and Standard Plan',
        billType: 'Separate Airtime and Device bills',
      },
    },
  });

  const billValues = form.watch('bill');

  const [step, setStep] = useState<RpiFormStep>('bill');

  const [result, setResult] = useState<{
    newTariff: number;
    increase: number;
  } | null>(null);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        const valid = rpiSchema.shape[step].safeParse(form.getValues()[step]);

        if (!valid.success) {
          return form.trigger(step);
        }

        if (step === 'bill') {
          return setStep('calculate');
        }

        form.trigger('calculate');

        let isFormValid = true;

        for (const step of ['bill', 'calculate']) {
          const valid = rpiSchema.shape[step as RpiFormStep].safeParse(
            form.getValues()[step as RpiFormStep]
          );

          isFormValid = valid.success;
        }

        if (isFormValid) {
          const currentTariff = Number.parseInt(
            form.getValues().calculate.tariffCost
          );

          const increase = calculateTariffIncrease({
            rpi,
            increaseRate,
            currentTariff,
          });

          setResult({
            newTariff: currentTariff + increase,
            increase,
          });
        }
      }}
    >
      <VerticalRhythm spaceBetween='lg'>
        <Text as='h2' weight='bold' size='T600'>
          How do the changes affect you?
        </Text>
        <StepIndicator
          activeStep={step === 'bill' ? 0 : 1}
          numberOfSteps={1}
          label={getStepLabel(step)}
        />
        <HorizontalRule spacing='none' />
        {step === 'bill' && (
          <>
            <FormRadioGroup
              id='radio-group'
              label={`Do you have SIM Only and Standard Plan, or SIM and device?`}
              error={
                form.formState.errors.bill?.planType?.message ??
                form.formState.errors.bill?.message
              }
            >
              <VerticalRhythm spaceBetween='md'>
                {productType.map((item) => (
                  <FormRadioInput
                    key={item}
                    value={item}
                    isFlag
                    label={item}
                    type='radio'
                    {...form.register('bill.planType')}
                  />
                ))}
              </VerticalRhythm>
            </FormRadioGroup>
            {billValues.planType === 'SIM and device' && (
              <FormRadioGroup
                id='radio-group1'
                label='What does your bill look like?'
                error={
                  form.formState.errors.bill?.billType?.message ??
                  form.formState.errors.bill?.message
                }
              >
                <VerticalRhythm spaceBetween='md'>
                  {billType.map((item) => (
                    <FormRadioInput
                      key={item}
                      value={item}
                      isFlag
                      label={item}
                      {...form.register('bill.billType')}
                    />
                  ))}
                </VerticalRhythm>
              </FormRadioGroup>
            )}
            <Button
              type='submit'
              disabled={
                !rpiSchema.shape['bill'].safeParse(form.getValues('bill'))
                  .success
              }
            >
              Check charge
            </Button>
          </>
        )}
        {step === 'calculate' && (
          <>
            <Alert
              variant='info'
              title={getAlertLabel({ increaseRate, billValues })}
            >
              <Anchor
                asChild
                onClick={() => {
                  form.trigger('bill');
                  setStep('bill');

                  setResult(null);
                }}
              >
                <button type='button'>Edit answers</button>
              </Anchor>
            </Alert>
            <Text as='h3' size='T400' weight='bold'>
              Price change calculator
            </Text>
            <Text>
              To calculate how the changes affect you, enter your tariff cost,
              excluding any Bolt Ons, Extras or insurance.
            </Text>
            <Text>
              Not sure what cost to calculate? 
              <Anchor href='accounts.o2.co.uk'>Sign in to My O2 </Anchor> 
              {getCalculationText({
                start: props.termStart,
                end: props.termEnd,
              })}{' '}
            </Text>
            <FlexBlock
              gap='md'
              align='start'
              className={styles['flex-container']}
            >
              <div className={styles['input-container']}>
                <FormInput
                  className={styles['input-inner-container']}
                  id='tariffCost'
                  label='Current tariff cost'
                  affix='£'
                  placeholder='0.00'
                  {...form.register('calculate.tariffCost')}
                  error={form.formState.errors.calculate?.tariffCost}
                />
              </div>
              <Button
                className={clsx({
                  [styles['button']]: true,
                })}
                type='submit'
              >
                Calculate
              </Button>
            </FlexBlock>
            {result && (
              <>
                <Text size='T400' weight='bold'>
                  Your new tariff cost from {end}:{' '}
                </Text>
                <Text>{`£${result.newTariff} (an increase of £${result.increase})`}</Text>
              </>
            )}
          </>
        )}
      </VerticalRhythm>
    </form>
  );
};
