import { toPrice } from '@/lib/utils/money';

export interface RationaliseSpend {
  amount: number | string | null | undefined;
  in_allowance: boolean;
  is_free: boolean;
  unit: string;
}

export const rationaliseSpend = ({
  unit,
  amount,
  is_free,
  in_allowance,
}: RationaliseSpend): string | number => {
  if (amount && typeof amount === 'string') {
    if (isNaN(Number(amount))) {
      return amount;
    }
    return `${toPrice(amount)}/${unit}`;
  }

  if (is_free) {
    return 'Free';
  }

  if (in_allowance) {
    return 'UK Allowance';
  }

  if (!amount) {
    return 'Unknown';
  }

  return `${toPrice(amount)}/${unit}`;
};
