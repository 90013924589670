import clsx from 'clsx';

import { ComponentProps, forwardRef } from 'react';

import { FasCheck } from '@virginmediao2/momentum-icons/icons/fas';

import { Icon } from '@/components/momentum/components/icon';
import { Text } from '@/components/momentum/components/text';

import styles from './form-checkbox-group.module.scss';

export interface FormCheckboxInputProps extends ComponentProps<'input'> {
  label: string;
  description?: string;
  isSquare?: boolean;
  isFlag?: boolean;
  isSmall?: boolean;
  isBordered?: boolean;
  error?: boolean;
}

export const FormCheckboxInput = forwardRef<
  HTMLInputElement,
  FormCheckboxInputProps
>(
  (
    {
      id,
      label,
      isSquare = false,
      isFlag = false,
      isSmall = false,
      isBordered = false,
      error = false,
      ...props
    },
    ref
  ) => (
    <label
      data-testid='form-input-control'
      className={clsx(styles['form-input-control'], {
        [styles['form-input-control--is-square']]: isSquare,
        [styles['form-input-control--is-flag']]: !!isFlag,
        [styles['form-input-control--with-label']]: !!label,
        [styles['form-input-control--size-sm']]: isSmall,
        [styles['form-input-control--is-bordered']]: isBordered,
        [styles['form-input-control--is-invalid']]: !!error,
      })}
    >
      <span
        data-testid='form-input-control-signifier'
        className={styles['form-input-control__signifier']}
      >
        <input
          type='checkbox'
          className={styles['form-input-control__control']}
          {...props}
          ref={ref}
        />
        <span className={styles['form-input-control__icon']}>
          <Icon icon={FasCheck} />
        </span>
      </span>
      <span
        data-testid='form-input-label'
        className={clsx(styles['form-input-control__label'], {
          [styles['form-input-control__label--with-signifier']]: true,
        })}
      >
        <Text size='T300' weight='regular' color='default'>
          {label}
        </Text>
      </span>
    </label>
  )
);

FormCheckboxInput.displayName = 'FormCheckboxInput';
export default FormCheckboxInput;
