'use client';

import clsx from 'clsx';

import { Block, BlockProps } from '@virginmediao2/momentum-nextjs/block';
import { Button } from '@virginmediao2/momentum-nextjs/button';
import { Text } from '@virginmediao2/momentum-nextjs/text';
import { useCallback, useEffect, useState } from 'react';

import { FarTag } from '@virginmediao2/momentum-icons/icons/far';

import { Icon } from '@/components/momentum/components/icon';
import { Pill } from '@/components/momentum/components/pills';

import styles from './voucher.module.scss';

interface VoucherProps extends BlockProps {
  vouchercode?: string;
  vouchertext?: string;
  voucherbuttontext: string;
  buttonClickUrl?: string;
  showIcon?: boolean;
}

export const Voucher = ({
  children,
  vouchercode,
  vouchertext,
  voucherbuttontext,
  buttonClickUrl,
  showIcon = true,
  className,
  ...props
}: VoucherProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const handleCopy = useCallback(() => {
    if (vouchercode) {
      navigator.clipboard.writeText(vouchercode);
      setIsVisible(true);
    }
  }, [vouchercode]);

  useEffect(() => {
    handleCopy();
  }, [vouchercode, handleCopy]);

  return (
    <Block className={clsx(styles['voucher'], className)} {...props}>
      {isVisible && (
        <div
          data-testid='copied-text'
          className={styles['voucher__copy-status-container']}
        >
          <Pill
            variant='success'
            showIcon={true}
            style={
              {
                backgroundColor: '#EBF3EB',
                color: '#333',
                borderColor: '#333',
                marginBottom: '20px',
                '--pill-background-bright-color': '#f8f8f8',
                '--pill-icon-color': '#348437',
              } as React.CSSProperties
            }
            title='Code copied'
          />
        </div>
      )}
      <button
        data-testid='voucher-code-button'
        className={styles['voucher__code-button']}
        onClick={handleCopy}
        onKeyDown={(event) => {
          if (event.code === 'Enter') {
            event.preventDefault();
          }
        }}
      >
        {vouchercode && <Icon icon={FarTag} size='lg' />}
        <Text
          className={styles['voucher__text']}
          data-testid='voucher-code'
          size='T200'
          weight='bold'
          color='inherit'
        >
          {vouchercode}
        </Text>
      </button>
      <div className={styles['voucher__text-container']}>
        <Text
          className={styles['voucher--text']}
          data-testid='voucher-text'
          size='T200'
          weight='light'
          color='inherit'
        >
          {vouchertext}
        </Text>
      </div>
      <div className={styles['voucher__button-container']}>
        <Button
          className={styles['voucher__button-container--button']}
          variant='primary'
          onClick={() => {
            if (buttonClickUrl) {
              window.location.href = buttonClickUrl;
            }
          }}
        >
          {voucherbuttontext}
        </Button>
      </div>
    </Block>
  );
};
