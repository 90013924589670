export const linkToHref = (link: string): string => {
  const [, root, ...page] = link.split('/').filter(Boolean);
  const url = [root, ...page].join('/');

  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }

  return `/${url}`;
};
