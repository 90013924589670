'use client';

import { FC, useState } from 'react';

import {
  FasChevronDown,
  FasChevronUp,
} from '@virginmediao2/momentum-icons/icons/fas';

import { Icon } from '@/components/momentum/components/icon';
import { L2Category } from '@/components/storyblok/bloks/dynamo-megamenu/dropdown/l2-category';
import { DynamoNavigation } from '@/components/storyblok/types/dynamo-navigation';

import styles from './side-navigation-item.module.scss';

export interface SideNavigationItemProps {
  blok: DynamoNavigation;
}

export const SideNavigationItem: FC<SideNavigationItemProps> = ({ blok }) => {
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <button
        onClick={handleClick}
        className={styles['mega-menu-side-navigation-item__trigger']}
      >
        <div className={styles['mega-menu-side-navigation-item__trigger-icon']}>
          {expanded ? (
            <Icon icon={FasChevronUp} />
          ) : (
            <Icon icon={FasChevronDown} />
          )}
        </div>
        {blok?.title}
      </button>
      {expanded && (
        <div className={styles['mega-menu-side-navigation-item__items']}>
          <L2Category category={blok} />
        </div>
      )}
    </>
  );
};
