const defaultQuality = 75;

export default function storyblokLoader({
  src,
  width,
  quality,
}: {
  src: string;
  width: number;
  quality?: number;
}) {
  if (!src) {
    console.error('Storyblok loader error: "src" is undefined');
    return '';
  }

  try {
    const url = new URL(src);
    const regex = /https:\/\/a\.storyblok\.com\/f\/\d+\/(\d+)x(\d+)\//;

    // Ensure the URL is a valid Storyblok URL
    if (!regex.test(src)) {
      return src;
    }

    // Skip SVG transformations
    if (url.pathname.endsWith('.svg')) {
      return src;
    }

    // Format URL for Storyblok CDN with resizing proportionally to width
    url.pathname = `${url.pathname}/m/${width}x0`;
    const params = new URLSearchParams();

    if (quality) {
      params.set('quality', (quality || defaultQuality).toString());
    }

    url.search = params.toString();
    return url.toString();
  } catch (error) {
    console.error('Storyblok image loader error:', error);
    return src;
  }
}
