import clsx from 'clsx';

import Link, { LinkProps } from 'next/link';
import type {
  ComponentProps,
  ComponentPropsWithoutRef,
  ReactNode,
} from 'react';
import { forwardRef } from 'react';

import styles from './anchor.module.scss';

export interface AnchorProps
  extends LinkProps,
    Omit<ComponentPropsWithoutRef<'a'>, 'href'> {
  icon?: ReactNode;
  children: ReactNode;
  color?: 'light' | 'dark' | 'inherit' | string; // TODO - Investigate how to prevent Storyblok from passing in an empty string when value is not set
  size?: 'sm' | 'inherit' | string; // TODO - Investigate how to prevent Storyblok from passing in an empty string when value is not set
  /** Expands the link to fill the parent container */
  expand?: boolean;
  className?: string;
  decoration?: 'none' | 'hover';
  isImage?: boolean;
  iconPosition?: 'start' | 'end';
  iconOnly?: boolean | 'md-down' | 'sm-down';
  spanProps?: Omit<ComponentProps<'span'>, 'className'>;
  href: string;
  clickTracking?: string;
}

export const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>(
  (
    {
      icon,
      children,
      color,
      className,
      decoration,
      expand,
      size,
      iconPosition,
      iconOnly,
      isImage,
      spanProps,
      href,
      clickTracking,
      ...props
    },
    ref
  ) => {
    return (
      <Link
        href={href}
        prefetch={false}
        className={clsx(
          {
            [styles['anchor']]: true,
            [styles['anchor--color-inherit']]: color === 'inherit',
            [styles['anchor--color-light']]: color === 'light',
            [styles['anchor--color-dark']]: color === 'dark',
            [styles['anchor--size-sm']]: size === 'sm',
            [styles['anchor--size-inherit']]: size === 'inherit',
            [styles['anchor--is-image']]: isImage,
            [styles['anchor--expand']]: expand,
            [styles['anchor--decoration-hover']]: decoration === 'hover',
            [styles['anchor--decoration-none']]: decoration === 'none',
            [styles['anchor--icon-only']]: iconOnly === true,
            [styles['anchor--icon-only-md-down']]: iconOnly === 'md-down',
            [styles['anchor--icon-only-sm-down']]: iconOnly === 'sm-down',
            [styles['anchor--icon']]: icon,
            [styles['anchor--icon-position-start']]: iconPosition === 'start',
            [styles['anchor--icon-position-end']]: iconPosition === 'end',
          },
          className
        )}
        ref={ref}
        {...(clickTracking ? { manual_cm_re: clickTracking } : null)}
        {...props}
      >
        <span {...spanProps} className={styles['anchor__text']}>
          {children}
        </span>
        {icon && <span className={styles['anchor__icon']}>{icon}</span>}
      </Link>
    );
  }
);

Anchor.displayName = 'Anchor';
