import { FC, ReactNode } from 'react';

import styles from './navigation.module.scss';

export interface MegaMenuNavigationProps {
  children?: ReactNode;
}

export const MegaMenuNavigation: FC<MegaMenuNavigationProps> = ({
  children,
}) => <ul className={styles['mega-menu-navigation']}>{children}</ul>;
