import { RelLink } from '@/components/storyblok/types/rel-link';

import { linkToHref } from '../link-to-href';

export const coerceUrl = (link?: RelLink): string | undefined => {
  if (!link) {
    return undefined;
  }

  if (link.linktype === 'url') {
    return link.url;
  }

  if (link.linktype === 'story' && link.rel) {
    return linkToHref(link.rel.full_slug);
  }

  return undefined;
};
