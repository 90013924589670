import { z } from 'zod';

export const TARIFF_TYPES = [
  'Select Tariff',
  'Pay Monthly',
  'Pay As You Go',
  'Business',
] as const;

export const PHONE_TYPES = [
  'Select Phone',
  'Apple iPhone',
  'Palm Pre',
  'Blackberry',
  'LG',
  'O2',
  'Nokia',
  'Motorola',
  'Samsung',
  'Sony Ericsson',
  'Other',
] as const;

const airTimeBalanceRegex = /^[1-9][.\d]*(,\d+)?$/;

const imeiRegex = /\d{15}/;

const emailMatchMessage = `The email addresses you supplied don't match. Check them and try again.`;

const emailValidMessage = `The email address you supplied doesn't appear to be valid.`;

const ukPhoneMessage = `The mobile phone number you supplied isn't valid. Check and re-enter the number.`;

const requiredMessage = `Fill in to continue.`;

const imeiMessage = `The supplied IMEI doesn't appear to be valid. It must be of 15 digits.`;

const amountMessage = `The amount you supplied isn't valid. Enter again in the format 0.00, with no currency symbols.`;

const tariffErrorMessage = `Please select a tariff`;

const phoneErrorMessage = `Please select a phone`;

const phoneSchema = z
  .string()
  .trim()
  .min(1, { message: requiredMessage })
  .refine((val) => val.length === 11 && val.substring(0, 2) === '07', {
    message: ukPhoneMessage,
  });

const detailsSchema = z
  .object({
    tariff: z.enum(TARIFF_TYPES).refine(
      (tariff) => {
        return tariff !== 'Select Tariff';
      },
      { message: tariffErrorMessage }
    ),
    phone: z.enum(PHONE_TYPES).refine(
      (phone) => {
        return phone !== 'Select Phone';
      },
      { message: phoneErrorMessage }
    ),
    mobileNumber: phoneSchema,
    firstName: z.string().min(2, { message: requiredMessage }),
    lastName: z.string().min(2, { message: requiredMessage }),
    alternativeNumber: z.string().refine(
      (phoneNumber) => {
        if (!phoneNumber) {
          return true;
        }

        return (
          phoneNumber.length === 11 && phoneNumber.substring(0, 2) === '07'
        );
      },
      { message: ukPhoneMessage }
    ),
    email: z
      .string()
      .min(1, { message: requiredMessage })
      .email({ message: emailValidMessage }),
    confirmEmail: z
      .string()
      .min(1, { message: requiredMessage })
      .email({ message: emailValidMessage }),
  })
  .refine(
    (schema) => {
      return schema.email == schema.confirmEmail;
    },
    () => ({
      message: emailMatchMessage,
      path: ['email'],
    })
  );

const businessSecuritySchema = z.object({
  companyName: z.string(),
  firstLine: z.string().min(1, { message: requiredMessage }),
  postCode: z.string().min(1, { message: requiredMessage }),
  password: z.string().min(1, { message: requiredMessage }),
});

const payMonthlySecuritySchema = z.object({
  char2: z.string().min(1, { message: requiredMessage }),
  char3: z.string().min(1, { message: requiredMessage }),
});

const forgottenPasswordSchema = z.object({
  companyName: z.string(),
  firstLine: z.string().min(1, { message: requiredMessage }),
  postCode: z.string().min(1, { message: requiredMessage }),
  amountOfLastBill: z
    .string()
    .min(1, { message: requiredMessage })
    .regex(airTimeBalanceRegex, { message: amountMessage }),
  dateOfLastBill: z.string().date(),
});

const payMonthlyforgottenPasswordSchema = z.object({
  amountOfLastBill: z
    .string()
    .min(1, { message: requiredMessage })
    .regex(airTimeBalanceRegex, { message: amountMessage }),
  dateOfLastBill: z.string().date(),
  tariff: z.string().min(1, { message: requiredMessage }),
});

const additionalInformationSchema = z.object({
  model: z.string().min(1, { message: requiredMessage }),
  imei: z.string().regex(imeiRegex, { message: imeiMessage }),
  additionalImei: z.string(),
});

export const unlockPhoneSchema = z.object({
  details: detailsSchema,
  businessSecurity: businessSecuritySchema,
  payMonthlySecurity: payMonthlySecuritySchema,
  payAsYouGoSecurity: payMonthlySecuritySchema.extend({
    airTimeBalance: z
      .string()
      .min(1, { message: requiredMessage })
      .regex(airTimeBalanceRegex, { message: amountMessage }),
    frequentNumber1: phoneSchema,
    frequentNumber2: phoneSchema,
  }),
  forgottenPassword: forgottenPasswordSchema,
  payMonthlyforgottenPassword: payMonthlyforgottenPasswordSchema,
  additionalInformation: additionalInformationSchema,
});
