import { z } from 'zod';

export interface RPIProps {
  rpi?: string;
  increaseRate?: string;
}

export const productType = [
  'SIM Only and Standard Plan',
  'SIM and device',
] as const;

export const billType = [
  'Separate Airtime and Device bills',
  'Single bill including your device cost',
] as const;

export const amountMessage = `The amount you supplied isn't valid. Enter again in the format 0.00, with no currency symbols.`;

export const airTimeBalanceRegex = /^[1-9][.\d]*(,\d+)?$/;

export const rpiSchema = z.object({
  bill: z.object({
    planType: z.enum(productType).optional(),
    billType: z.enum(billType).optional(),
  }),
  calculate: z.object({
    tariffCost: z
      .string()
      .regex(airTimeBalanceRegex, { message: amountMessage })
      .refine(
        (value) => {
          if (Number.parseInt(value) > 300) {
            return false;
          }

          return true;
        },
        { message: 'Price must be £300 or below.' }
      ),
  }),
});

export type RpiForm = z.infer<typeof rpiSchema>;

export type RpiFormStep = keyof RpiForm;
