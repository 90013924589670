'use client';

import clsx from 'clsx';

import { Tabs } from '@virginmediao2/momentum-nextjs/tabs';
import type { FC } from 'react';
import { useMedia } from 'react-use';

import styles from './dynamo-tabs.module.scss';

export type TabsProps = {
  children: any;
  tabsLength: number;
  controls?: boolean;
  fullWidth?: boolean;
};

export const ClientTabs: FC<TabsProps> = ({
  children,
  tabsLength,
  controls,
  fullWidth,
}) => {
  const isMobile = useMedia('(width < 48em)', false);
  const canHaveControls = tabsLength > 2;
  const localControls = (isMobile && canHaveControls) || controls;
  return (
    <Tabs
      controls={localControls}
      className={clsx({ [styles['tab-full-width']]: fullWidth })}
    >
      {children}
    </Tabs>
  );
};
