import clsx from 'clsx';

import type { ComponentProps, FC } from 'react';

import styles from './vertical-rule.module.css';

export interface VerticalRuleProps extends ComponentProps<'hr'> {
  spacing: 'none' | 'xs' | 'sm' | 'md' | 'lg';
}

export const VerticalRule: FC<VerticalRuleProps> = ({ spacing, ...props }) => {
  return (
    <div
      className={clsx({
        [styles['vertical-rule']]: true,
        [styles['vertical-rule--margin-none']]: spacing === 'none',
        [styles['vertical-rule--margin-xs']]: spacing === 'xs',
        [styles['vertical-rule--margin-sm']]: spacing === 'sm',
        [styles['vertical-rule--margin-md']]: spacing === 'md',
        [styles['vertical-rule--margin-lg']]: spacing === 'lg',
      })}
      {...props}
    />
  );
};
