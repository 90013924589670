import { toPrice } from '@/lib/utils/money';

import { IntlCallRatesBlok } from '../types';
import { rationaliseSpend } from './rationalise-spend';

export const useRates = (rate: IntlCallRatesBlok) => {
  const paym_inbound = toPrice(
    rationaliseSpend({
      amount: rate.paym_std_inbound_price,
      in_allowance: rate.paym_std_inbound_in_allowance,
      is_free: rate.paym_std_inbound_free,
      unit: 'min',
    })
  );

  const paym_outbound = toPrice(
    rationaliseSpend({
      amount: rate.paym_std_outbound_price,
      in_allowance: rate.paym_std_outbound_in_allowance,
      is_free: rate.paym_std_outbound_free,
      unit: 'min',
    })
  );

  const paym_sms = toPrice(
    rationaliseSpend({
      amount: rate.paym_sms_price,
      in_allowance: rate.paym_sms_in_allowance,
      is_free: rate.paym_sms_free,
      unit: 'text',
    })
  );

  const paym_data = toPrice(
    rationaliseSpend({
      amount: rate.paym_data_price,
      in_allowance: rate.paym_data_in_allowance,
      is_free: rate.paym_data_free,
      unit: 'MB',
    })
  );

  const payg_inbound = toPrice(
    rationaliseSpend({
      amount: rate.payg_std_inbound_price,
      in_allowance: rate.payg_std_inbound_in_allowance,
      is_free: rate.payg_std_inbound_free,
      unit: 'min',
    })
  );

  const payg_outbound = toPrice(
    rationaliseSpend({
      amount: rate.payg_std_outbound_price,
      in_allowance: rate.payg_std_outbound_in_allowance,
      is_free: rate.payg_std_outbound_free,
      unit: 'min',
    })
  );

  const payg_sms = toPrice(
    rationaliseSpend({
      amount: rate.payg_sms_price,
      in_allowance: rate.payg_sms_in_allowance,
      is_free: rate.payg_sms_free,
      unit: 'text',
    })
  );

  const payg_data = toPrice(
    rationaliseSpend({
      amount: rate.payg_data_price,
      in_allowance: rate.payg_data_in_allowance,
      is_free: rate.payg_data_free,
      unit: 'MB',
    })
  );

  return {
    paym_inbound,
    paym_outbound,
    paym_sms,
    paym_data,
    payg_inbound,
    payg_outbound,
    payg_sms,
    payg_data,
  };
};
