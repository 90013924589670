import clsx from 'clsx';

import { ComponentProps, FC, ReactNode } from 'react';

import styles from './vmo2-theme.module.scss';

export interface Vmo2ThemeProps
  extends Omit<ComponentProps<'div'>, 'className'> {
  className?: string;
  children: Readonly<ReactNode>;
}

export const Vmo2Theme: FC<Vmo2ThemeProps> = ({
  children,
  className,
  ...props
}) => (
  <div className={clsx(styles['host'], className)} {...props}>
    {children}
  </div>
);
