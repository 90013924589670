'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { Box } from '@virginmediao2/momentum-nextjs/box';
import { Button } from '@virginmediao2/momentum-nextjs/button';
import {
  ErrorAlert,
  ErrorAlertsRef,
  FormInput,
  FormRadioGroup,
  FormRadioInput,
} from '@virginmediao2/momentum-nextjs/forms';
import { Text } from '@virginmediao2/momentum-nextjs/text';
import { useRouter } from 'next/navigation';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { submitKeepMobileNumberForm } from '@/lib/submit-mobile-number';
import { mobileNumberRegex, pacCodeRegex } from '@/lib/utils/regex-validators';

import styles from './mobile-number-form.module.css';

const SUBMISSION_SUCCESS_URL =
  'https://www.o2.co.uk/help/device-and-sim-support/how-to-keep-your-mobile-number/online-form/thank-you';

enum TransferTypes {
  JOINING = 'joining',
  SWITCHING = 'switching',
}

const isValidNumber = (value: string | undefined) =>
  mobileNumberRegex.test(value ?? '');

const isValidPacCode = (pacCode: string | undefined) =>
  pacCodeRegex.test(pacCode ?? '');

const mobileNumberFormSchema = z
  .object({
    firstName: z.string().min(2, 'First name is required'),
    lastName: z.string().min(2, 'Last name is required'),
    email: z.string().email('Invalid email address'),
    mobileNumber: z.string().regex(mobileNumberRegex, 'Invalid mobile number'),
    transferType: z
      .nativeEnum(TransferTypes)
      .nullable()
      .refine(Boolean, 'Requires at least one option to be selected'),
    keepNumber: z
      .string()
      .regex(mobileNumberRegex, 'Invalid number to be transferred'),
    pacCode: z.string().optional(),
    tempNumber: z.string().optional(),
    simSerialNumber: z.string().optional(),
    sortCode: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    const { transferType, pacCode, tempNumber, simSerialNumber, sortCode } =
      data;

    if (!tempNumber || !isValidNumber(tempNumber)) {
      ctx.addIssue({
        code: 'custom',
        path: ['tempNumber'],
        message: 'Invalid number to change',
      });
    }

    if (transferType === TransferTypes.JOINING) {
      if (!pacCode || !isValidPacCode(pacCode)) {
        ctx.addIssue({
          code: 'custom',
          path: ['pacCode'],
          message: 'PAC is required and must be in the form ABC123456',
        });
      }

      if (!simSerialNumber) {
        ctx.addIssue({
          code: 'custom',
          path: ['simSerialNumber'],
          message: 'SIM serial number is required',
        });
      }
    }

    if (transferType === TransferTypes.SWITCHING) {
      if (!sortCode || sortCode.length !== 2) {
        ctx.addIssue({
          code: 'custom',
          path: ['sortCode'],
          message: 'Security check is required and must be 2 digits',
        });
      }
    }
  });

type MobileNumberFormData = z.infer<typeof mobileNumberFormSchema>;

export const MobileNumberForm = () => {
  const errorAlertsRef = useRef<ErrorAlertsRef>(null);
  const [transferType, setTransferType] = useState<TransferTypes>(
    TransferTypes.JOINING
  );
  const router = useRouter();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    setFocus,
  } = useForm<MobileNumberFormData>({
    resolver: zodResolver(mobileNumberFormSchema),
    mode: 'onSubmit',
  });

  const onSubmit = async (data: MobileNumberFormData) => {
    try {
      await submitKeepMobileNumberForm({
        ...data,
        otherNetwork: data.transferType === TransferTypes.JOINING,
      });
      router.push(SUBMISSION_SUCCESS_URL);
    } catch (error) {
      console.error('Submission error:', error);
    }
  };

  return (
    <Box
      data-testid='mobile-number-form'
      border={false}
      className={styles['mobile-number-form']}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {!isValid && isSubmitted && errors && (
          <Box margin='lg' marginAxis='y' border={false}>
            <ErrorAlert
              ref={errorAlertsRef}
              errors={errors}
              setFocus={setFocus}
            />
          </Box>
        )}

        <Box gap='lg' border={false}>
          <Box gap='lg' border={false}>
            <Text size='T500' weight='bold' as='h2'>
              About you
            </Text>
            <Text>
              Enter your contact details below, so we can keep you updated about
              your number transfer.
            </Text>
            <Box border={false} gap='lg'>
              <FormInput
                id='firstName'
                label={<Text>First name</Text>}
                {...register('firstName')}
                error={errors?.firstName}
                placeholder='e.g. John'
              />
              <FormInput
                id='lastName'
                label={<Text>Last name</Text>}
                {...register('lastName')}
                error={errors?.lastName}
                placeholder='e.g. Smith'
              />
              <FormInput
                id='email'
                label={<Text>Email address</Text>}
                {...register('email')}
                error={errors?.email}
                placeholder='e.g. j.smith@email.com'
              />
              <FormInput
                id='mobileNumber'
                label={<Text>Contact mobile number</Text>}
                {...register('mobileNumber')}
                error={errors?.mobileNumber}
                placeholder='e.g. 07543654437'
              />
            </Box>
          </Box>

          <Box gap='lg' border={false}>
            <Text size='T500' weight='bold' as='h2'>
              The number you want to keep
            </Text>
            <Text>Tell us about the number you want to transfer</Text>
            <Box gap='lg' border={false}>
              <FormRadioGroup
                id='transferType'
                error={errors?.transferType?.message}
              >
                <Box gap='lg' border={false} padding='sm'>
                  <FormRadioInput
                    isFlag
                    label={"I'm joining O2 from another network"}
                    value='joining'
                    {...register('transferType')}
                    onChange={() => setTransferType(TransferTypes.JOINING)}
                    checked={transferType === TransferTypes.JOINING}
                  />
                  <FormRadioInput
                    isFlag
                    label="I'm switching from O2 Pay As You Go to O2 Pay Monthly"
                    value='switching'
                    {...register('transferType')}
                    onChange={() => setTransferType(TransferTypes.SWITCHING)}
                    checked={transferType === TransferTypes.SWITCHING}
                  />
                </Box>
              </FormRadioGroup>
              <Box border={false}>
                <FormInput
                  id='keepNumber'
                  label='Number to be transferred'
                  {...register('keepNumber')}
                  error={errors?.keepNumber}
                  placeholder='e.g. 07543654437'
                />
              </Box>
            </Box>
          </Box>

          {transferType === TransferTypes.JOINING && (
            <>
              <Text size='T500' weight='bold' as='h2'>
                Your PAC
              </Text>
              <Text>
                To transfer your number, you&apos;ll need to ask your old
                network for a PAC (Porting Authorisation Code). You can get your
                PAC by texting &apos;PAC&apos; to 65075 from the number you want
                to keep - you should get it in a minute or two. Alternatively,
                you can contact your old network to get your PAC.
              </Text>
              <Text>
                A PAC is normally nine characters long, in the format
                &apos;ABC123456&apos;.
              </Text>
              <Box border={false}>
                <FormInput
                  id='pacCode'
                  label='Your PAC'
                  {...register('pacCode')}
                  error={errors.pacCode}
                  placeholder='e.g. ABC123456'
                />
              </Box>
            </>
          )}

          <Box gap='lg' border={false}>
            <Text size='T500' weight='bold' as='h2'>
              The number you want to change
            </Text>
            <Text>
              When transferring your number to O2, we&apos;ll give you a
              temporary number to begin with, which we&apos;ll replace with the
              number you want to keep. You&apos;ll find it on your O2 Pay As You
              Go sim pack.
            </Text>
            <Text>Please enter this temporary number below.</Text>
            <Text>This number is Pay As You Go</Text>
            <Box border={false}>
              <FormInput
                id='tempNumber'
                label='Number to change'
                {...register('tempNumber')}
                error={errors.tempNumber}
                placeholder='e.g. 07543654437'
              />
            </Box>
          </Box>

          {transferType === TransferTypes.JOINING && (
            <>
              <Text size='T500' weight='bold' as='h2'>
                Your new sim
              </Text>
              <Text>
                Please enter the serial number of your new O2 sim, or esim.
              </Text>
              <Text>
                You&apos;ll find this printed on the back of your plastic sim,
                or on your esim pack. It&apos;s 19 digits long and begins with
                &apos;89441100&apos;.
              </Text>
              <Text>
                Smaller sims may only have the last 13 digits printed on them,
                beginning with &apos;00&apos;.
              </Text>
              <Box border={false}>
                <FormInput
                  id='simSerial'
                  label='Sim serial number'
                  {...register('simSerialNumber')}
                  error={errors.simSerialNumber}
                  placeholder='89441100 56573212244'
                />
              </Box>
            </>
          )}

          {transferType === TransferTypes.SWITCHING && (
            <>
              <Text size='T500' weight='bold' as='h2'>
                Security check
              </Text>
              <Text>
                We just need to check it&apos;s you. Please enter the last two
                digits of your bank&apos;s sort code. This needs to be the
                account you&apos;re using to pay your O2 bills. Your sort code
                usually appears on your debit card or bank statements in the
                format &apos;00-00-00&apos;.
              </Text>
              <Box border={false}>
                <FormInput
                  id='sortCode'
                  type='number'
                  maxLength={2}
                  label='Last two digits of your sort code'
                  {...register('sortCode')}
                  error={errors.sortCode}
                />
              </Box>
            </>
          )}

          <Button type='submit'>Continue</Button>
        </Box>
      </form>
    </Box>
  );
};
