'use client';

import { FC, useEffect, useState } from 'react';

import { FasBars, FasXmark } from '@virginmediao2/momentum-icons/icons/fas';

import { Icon } from '@/components/momentum/components/icon';

import styles from './side-navigation-trigger.module.scss';

export const SideNavigationTrigger: FC = () => {
  const [showSideNav, setShowSideNav] = useState(false);

  useEffect(() => {
    const handleVisibilityChange = (event: CustomEvent) => {
      setShowSideNav(event.detail.isVisible);
    };

    window.addEventListener(
      'sidenavvisibilitychange',
      handleVisibilityChange as EventListener
    );

    return () => {
      window.removeEventListener(
        'sidenavvisibilitychange',
        handleVisibilityChange as EventListener
      );
    };
  }, []);

  return (
    <button
      className={styles['mega-menu-side-navigation-trigger']}
      aria-label={`${showSideNav ? 'hide' : 'show'} menu`}
      aria-expanded={showSideNav}
      aria-controls={'mega-menu-side-navigation'}
      data-sidenav-trigger
    >
      {showSideNav ? <Icon icon={FasXmark} /> : <Icon icon={FasBars} />}
    </button>
  );
};
