'use client';

import Script from 'next/script';
import { FC } from 'react';

export interface SpeedPerformEmbedProps {
  articleType: string;
}

export const SpeedPerformEmbed: FC<SpeedPerformEmbedProps> = ({
  articleType,
}: SpeedPerformEmbedProps) => {
  let contentId, clientId, spBaseUrl;

  switch (articleType) {
    case 'stage':
      contentId = '3832';
      clientId = '229';
      spBaseUrl = 'https://o2.wm-staging.com';
      break;
    case 'current_prod':
      contentId = '3304';
      clientId = '158';
      spBaseUrl = 'https://cdn.sp-selfservice.com/cs';
      break;
    case 'new_prod':
      contentId = '5060';
      clientId = '158';
      spBaseUrl = 'https://cdn.sp-selfservice.com/cs';
      break;
  }

  return (
    <>
      <Script
        src={`${spBaseUrl}/embed/${clientId}/sp-content-widget.js`}
        strategy='afterInteractive'
      />
      <div
        className='sp-content-widget'
        data-widget-type='1'
        data-content-id={contentId}
        data-client-id={clientId}
        data-render-type='1'
      />
    </>
  );
};
