import clsx from 'clsx';

import type { FC, ReactNode } from 'react';
import { Children } from 'react';

import styles from './content.module.scss';

export interface ContentProps {
  children?: ReactNode;
  align?: 'center';
  color?:
    | 'default'
    | 'inherit'
    | 'success'
    | 'error'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark';
  __html?: string;
  tableScroll?: boolean;
  noTableHeader?: boolean;
}

export const Content: FC<ContentProps> = ({
  children,
  align,
  color,
  __html,
  tableScroll,
  noTableHeader,
}) => {
  const className = clsx({
    [styles['content']]: true,
    [styles['content--align-center']]: align === 'center',
    [styles['content--color-inherit']]: color === 'inherit',
    [styles['content--color-success']]: color === 'success',
    [styles['content--color-error']]: color === 'error',
    [styles['content--color-warning']]: color === 'warning',
    [styles['content--color-info']]: color === 'info',
    [styles['content--color-light']]: color === 'light',
    [styles['content--color-dark']]: color === 'dark',
    [styles['content--table-scroll']]: tableScroll,
    [styles['content--no-table-header']]: noTableHeader,
  });

  if (__html) {
    return (
      <div className={className} dangerouslySetInnerHTML={{ __html }}></div>
    );
  }

  if (Children.count(children)) {
    return <div className={className}>{children}</div>;
  }

  return null;
};
