import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from 'date-fns';

const getDayCopy = (numberOfDays: number) => {
  return `${numberOfDays} ${numberOfDays > 1 ? 'days' : 'day'}`;
};

const getHourCopy = (hours: number) => {
  if (hours > 0) {
    return `${hours} hours `;
  }

  return '';
};

export const displayTimeDifference = (
  startDateStr: string,
  endDateStr: string
): string => {
  let result = '';

  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  const now = new Date();

  const alreadyStarted = startDate < now;

  const willStartInTheFuture = !alreadyStarted;

  const hoursUntilEnd = differenceInHours(endDate, now);
  const minutesUntilEnd = differenceInMinutes(endDate, now) % 60;
  const daysUntilEnd = differenceInDays(endDate, now);

  const daysSinceStart = differenceInDays(startDate, now);
  const hoursSinceStart = differenceInHours(startDate, now) % 24;
  const minutesSinceStart = differenceInMinutes(startDate, now) % 60;

  const hasLessThanMinuteRemaining = minutesUntilEnd === 0;

  if (now >= endDate) {
    return 'Offer has passed';
  }

  if (alreadyStarted && hoursUntilEnd < 24 && hasLessThanMinuteRemaining) {
    result = 'Ends in less than a min';
  }

  if (alreadyStarted && hoursUntilEnd < 24 && !hasLessThanMinuteRemaining) {
    result = `Ends in ${getHourCopy(hoursUntilEnd)}${minutesUntilEnd} min`;
  }

  if (alreadyStarted && hoursUntilEnd >= 24) {
    result = `Ends in ${getDayCopy(daysUntilEnd)} ${hoursUntilEnd % 24} hours ${minutesUntilEnd} min`;
  }

  if (willStartInTheFuture && daysSinceStart > 0) {
    result = `Starts in ${daysSinceStart} days ${getHourCopy(hoursSinceStart)}`;
  }

  if (willStartInTheFuture && daysSinceStart === 0) {
    result = `Starts in ${getHourCopy(hoursSinceStart)}${minutesSinceStart} min`;
  }

  return result;
};
