'use client';

import { useEffect } from 'react';

import { ButtonLink } from '@/components/momentum/components/button/button-link';
import Flex from '@/components/momentum/components/flex-box';
import { Section } from '@/components/momentum/components/section';
import { Text } from '@/components/momentum/components/text';
import { ThemeSwitcher } from '@/components/momentum/theme/theme-switcher';

import styles from './error.module.css';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error('error', error);
  }, [error]);

  return (
    <ThemeSwitcher theme='o2_rebrand' className={styles['page']}>
      <Section layout='cozy'>
        <Flex
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          gap={32}
          height='100vh'
        >
          <Text as='h2' size='T900' weight='heavy'>
            Uh oh...
          </Text>
          <Text as='p' size='T500'>
            Oops.. something’s gone wrong.
          </Text>
          <ButtonLink href='/' variant='primary' decoration='outline'>
            Go to our homepage
          </ButtonLink>
        </Flex>
      </Section>
    </ThemeSwitcher>
  );
}
