import clsx from 'clsx';

import React from 'react';

import { FarXmark } from '@virginmediao2/momentum-icons/icons/far';

import { Icon } from '@/components/momentum/components/icon';
import { Text } from '@/components/momentum/components/text';

import { FormItemError } from '../form-item/form-item-error';
import styles from './form-checkbox-group.module.scss';

export type FormCheckboxGroupProps = {
  id: string;
  label?: React.ReactNode;
  description?: React.ReactNode;
  error?: React.ReactNode;
  children: React.ReactNode;
};

export const FormCheckboxGroup = React.forwardRef<
  HTMLDivElement,
  FormCheckboxGroupProps
>(({ id, label, description = '', error, children }, ref) => (
  <div
    data-testid={`form-multi-item-${id}`}
    className={clsx(styles['form-multi-item'])}
    aria-labelledby='form-multi-item-label'
    role='group'
  >
    {label && (
      <div
        data-testid={`form-multi-item-label-${id}`}
        className={clsx(styles['form-multi-item__label'])}
      >
        <Text size='T300' weight='regular' color='default'>
          {label}
        </Text>
      </div>
    )}
    {description && (
      <div
        data-testid={`form-multi-item-description-${id}`}
        className='form-multi-item__description'
      >
        <Text size='T300' weight='regular' color='default'>
          {description}
        </Text>
      </div>
    )}
    <div
      data-testid={`form-multi-item-controls-${id}`}
      className={clsx(styles['form-multi-item__controls'])}
      ref={ref}
    >
      {children}
    </div>
    {error && (
      <FormItemError
        id={`form-item-error-${id}`}
        data-testid={`form-item-error-${id}`}
      >
        <Icon icon={FarXmark} />
        <div className={styles['form-item__error-message']}>
          <p>{error}</p>
        </div>
      </FormItemError>
    )}
  </div>
));

FormCheckboxGroup.displayName = 'FormCheckboxGroup';
