import clsx from 'clsx';

import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { type ComponentProps, type FC, useCallback, useMemo } from 'react';

import { Button } from '../button';
import { Text } from '../text';
import styles from './filter-chip.module.scss';

export interface FilterChipProps
  extends Omit<ComponentProps<'div'>, 'className'> {
  label: string;
  value: string;
  linkUrl?: string;
  filterTags: string[];
  params: URLSearchParams;
  multiselect: boolean;
  pathname: string;
  router: AppRouterInstance;
  isActive: boolean;
  disabled: boolean;
  onFilterClick: (
    label: string,
    linkUrl: string | undefined,
    filterTags: string[],
    params: URLSearchParams,
    multiselect: boolean,
    pathname: string,
    router: AppRouterInstance
  ) => void;
}

export const FilterChip: FC<FilterChipProps> = ({
  label,
  value,
  linkUrl,
  filterTags,
  params,
  multiselect,
  pathname,
  router,
  isActive = false,
  disabled,
  onFilterClick,
}) => {
  const buttonClassNames = useMemo(() => {
    return clsx({
      [styles['filter-chip']]: true,
      [styles['filter-chip--active']]: isActive,
      [styles['filter-chip--disabled']]: disabled,
    });
  }, [isActive, disabled]);

  const handleClick = useCallback(() => {
    onFilterClick(
      value,
      linkUrl,
      filterTags,
      params,
      multiselect,
      pathname,
      router
    );
  }, [
    value,
    linkUrl,
    filterTags,
    params,
    multiselect,
    pathname,
    router,
    onFilterClick,
  ]);

  return (
    <Button
      className={buttonClassNames}
      id='filter-chip'
      data-testid='filter-chip'
      decoration='outline'
      variant='primary'
      onClick={handleClick}
    >
      <Text color='inherit'>{label}</Text>
    </Button>
  );
};
