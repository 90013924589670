import { format } from 'date-fns';

import { RpiForm, RpiFormStep } from './schema';

const defaultEndDate = '2025 04 01';
const defaultStartDate = '2024 04 01';

export const getFormattedTermDates = ({
  end,
  start,
}: {
  end?: string;
  start?: string;
}) => {
  return {
    end: format(end ?? defaultEndDate, 'MMMM yy'),
    start: format(start ?? defaultStartDate, 'MMMM yy'),
  };
};

export const getCalculationText = (termDate: {
  end?: string;
  start?: string;
}) => {
  const { start, end } = getFormattedTermDates(termDate);

  return `and
              you’ll be able to find your current plan on any statement issued
              between ${start} and ${end}.
`;
};

export const getAlertLabel = ({
  billValues,
  increaseRate,
}: {
  billValues: RpiForm['bill'];
  increaseRate: number;
}) => {
  let alertMessage = `Your Entire bill (excluding Bolt ons, Extras and insurance) is affected
        by RPI plus ${increaseRate}%.`;

  if (billValues?.planType === 'SIM Only and Standard Plan') {
    alertMessage = `Only your Airtime (excluding Bolt ons, Extras and insurance) is affected by RPI plus ${increaseRate}%.`;
  }

  if (
    billValues?.planType === 'SIM and device' &&
    billValues?.billType === 'Separate Airtime and Device bills'
  ) {
    alertMessage = `Only your Airtime Plan (excluding Bolt ons, Extras and insurance) is affected by RPI plus 3.9%. Your device plan is not subject to a RPI price.`;
  }

  return alertMessage;
};

export const getStepLabel = (step: RpiFormStep) => {
  let label = `Step 1 of 2: About your contract`;

  if (step === 'calculate') {
    label = `Step 2 of 2: Results`;
  }

  return label;
};

export const calculateTariffIncrease = ({
  increaseRate,
  rpi,
  currentTariff,
}: {
  increaseRate: number;
  rpi: number;
  currentTariff: number;
}): number => {
  const combinedIncreasePercentage = (increaseRate + rpi) / 100;

  const increase = currentTariff * combinedIncreasePercentage;

  return Number.parseFloat(increase.toFixed(2));
};
