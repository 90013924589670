'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { Box } from '@virginmediao2/momentum-nextjs/box';
import { FlexBlock } from '@virginmediao2/momentum-nextjs/flex-block';
import { FormInput } from '@virginmediao2/momentum-nextjs/forms';
import { Text } from '@virginmediao2/momentum-nextjs/text';
import React from 'react';
import { useFormState } from 'react-dom';
import { useForm } from 'react-hook-form';

import { Alert } from '@/components/momentum/components/alert';
import { imeiCheckerAction } from '@/lib/imei-checker';

import styles from './imei.module.scss';
import {
  DeviceCheckerFormProps,
  constructAlert,
  imeiNumberSchema,
} from './utils';

const mainCopy = `Enter the first 8 digits of your IMEI number into the field below.`;

export const ImeiNumberForm = (props: DeviceCheckerFormProps) => {
  const [actionStatus, actionHandler] = useFormState(imeiCheckerAction, {
    payload: undefined,
    error: undefined,
  });

  const form = useForm<z.infer<typeof imeiNumberSchema>>({
    resolver: zodResolver(imeiNumberSchema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    errors: actionStatus.fieldError,
  });

  const derivedData = constructAlert(actionStatus.payload);

  return (
    <Box border={false}>
      <Text size='T400'>{mainCopy}</Text>
      <Box gap='md' border={false}>
        <form
          onSubmit={form.handleSubmit((data) => {
            const payload: {
              imeiNumber: string;
              url?: string;
            } = { imeiNumber: data.imeiNumber };

            if (props.url) {
              payload.url = props.url;
            }

            actionHandler(payload);
          })}
        >
          <FlexBlock gap='md' align='start' padding='xs'>
            <FormInput
              {...form.register('imeiNumber')}
              id='imeiNumber'
              label={<Text>{'IMEI Number'}</Text>}
              placeholder='i.e. 35335767'
              error={form.formState.errors.imeiNumber}
            />
            <div className={styles['submit-button-container']}>
              <FormInput type='submit' name='find' id='find' value='Find' />
            </div>
          </FlexBlock>
        </form>
        {derivedData?.status === 'success' && (
          <Alert title='Great news' variant='success'>
            {derivedData.message}
          </Alert>
        )}
        {derivedData?.status === 'error' && (
          <Alert
            title='Unfortunately this device is not volt capable'
            variant='error'
          >
            {derivedData.message}
          </Alert>
        )}
        {actionStatus.error && (
          <Alert title={actionStatus.error} variant='error' />
        )}
      </Box>
    </Box>
  );
};
