'use client';

import clsx from 'clsx';

import { Flex } from '@virginmediao2/momentum-nextjs/flex';
import { FlexBlock } from '@virginmediao2/momentum-nextjs/flex-block';
import { Icon } from '@virginmediao2/momentum-nextjs/icon';
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';
import { useMedia, useScroll, useWindowSize } from 'react-use';
import { useDraggable } from 'react-use-draggable-scroll';

import { FalChevronRight } from '@virginmediao2/momentum-icons/icons/fal';
import {
  FarChevronLeft,
  FarChevronRight,
} from '@virginmediao2/momentum-icons/icons/far';
import { FasCircle } from '@virginmediao2/momentum-icons/icons/fas';

import { Anchor } from '../anchor';
import { Button } from '../button';
import { Text } from '../text';
import styles from './rail.module.scss';

interface RailScrollerProps {
  snap?: boolean;
  children: ReactNode;
  className?: string;
  peek?: boolean;
}

interface RailTitleProps {
  title?: string;
  viewAllLink?: string;
  children?: ReactNode;
}

interface RailControlsProps {}

interface RailRootProps {
  children: ReactNode;
}

interface RailContext {
  /**
   * Ref to the contianer that has the scroller.
   */
  scrollerContainerRef: React.MutableRefObject<HTMLElement | null>;
  /**
   * Id of the the contianer.
   */
  scrollerContainerId: string;
  /**
   * Are we at the left end of the rail.
   */
  canScrollLeft: boolean;
  /**
   * Are we at the right end of the rail.
   */
  canScrollRight: boolean;
  /**
   * Based on the current scroll position which is the active dot. Active dot is
   * the current viewport within a scroller.
   */
  activeDot: number;
  /**
   * Number of dots i.e. how many viewports we can fit into the scroller.
   */
  numberOfDots: number;
  /**
   * Observer for window width. Gives back a boolean if we are on mobile.
   */
  isMobile: boolean;
  /**
   * Jumps to the selected dot. If there are only two dots, one will jump to the
   * beginning one will jump to the end.
   */
  jumpToDot: (index: number) => void;
  /**
   * Steps one dot in either direction.
   */
  stepToDirection: (direction: 'left' | 'right') => void;
}

const RailContext = createContext<RailContext>(null as any);

const Root: React.FC<RailRootProps> = (props) => {
  const scrollerContainerId = useId();
  const scrollerContainerRef = useRef<HTMLElement | null>(null);

  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [activeDot, setActiveDot] = useState(0);

  const { x } = useScroll(scrollerContainerRef);
  const { width } = useWindowSize();

  useEffect(() => {
    const offset = 0;

    const width = scrollerContainerRef.current?.scrollWidth || Infinity;
    const scrollRight =
      x + (scrollerContainerRef.current?.offsetWidth || Infinity);

    setCanScrollLeft(x > offset);
    setCanScrollRight(scrollRight < width - offset);
  }, [x, scrollerContainerRef, width]);

  const isMobile = useMedia('(width < 48em)', false);

  const viewPortWidth = scrollerContainerRef.current?.clientWidth || Infinity;

  const scrollerWidth = scrollerContainerRef.current?.scrollWidth || Infinity;

  const numberOfViewPorts = Math.ceil(scrollerWidth / viewPortWidth);

  const numberOfDots = numberOfViewPorts > 0 ? numberOfViewPorts : 1;

  const evenlyDividedScrollerWidth = scrollerWidth / numberOfViewPorts;

  useEffect(() => {
    if (scrollerContainerRef.current) {
      scrollerContainerRef.current.onscroll = (event) => {
        const railViewPortWidth =
          scrollerContainerRef.current?.clientWidth || Infinity;

        const railScrollPosition =
          (event.target as HTMLElement).scrollLeft || 0;

        setActiveDot(Math.ceil(railScrollPosition / railViewPortWidth));
      };
    }
  }, []);

  const jumpToDot = React.useCallback(
    (index: number) => {
      scrollerContainerRef.current?.scrollTo({
        left: evenlyDividedScrollerWidth * index,
        behavior: 'smooth',
      });
    },
    [evenlyDividedScrollerWidth]
  );

  const stepToDirection = React.useCallback(
    (direction: 'left' | 'right') => {
      const multiplier = direction === 'left' ? -1 : 1;

      scrollerContainerRef.current?.scrollBy({
        left: multiplier * evenlyDividedScrollerWidth,
        behavior: 'smooth',
      });
    },
    [evenlyDividedScrollerWidth]
  );

  const bag = React.useMemo<RailContext>(() => {
    return {
      scrollerContainerRef,
      scrollerContainerId,
      activeDot,
      isMobile,
      canScrollRight,
      canScrollLeft,
      numberOfDots,
      jumpToDot,
      stepToDirection,
    };
  }, [
    scrollerContainerRef,
    scrollerContainerId,
    activeDot,
    isMobile,
    canScrollRight,
    canScrollLeft,
    numberOfDots,
    jumpToDot,
    stepToDirection,
  ]);

  return (
    <RailContext.Provider value={bag}>{props.children}</RailContext.Provider>
  );
};

const Scroller = ({ snap, className, children, peek }: RailScrollerProps) => {
  const { scrollerContainerRef, scrollerContainerId } = useContext(RailContext);

  const { events } = useDraggable(scrollerContainerRef as never, {
    isMounted: !!scrollerContainerRef,
  });

  return (
    <section aria-label='rail'>
      <ul
        id={scrollerContainerId}
        ref={scrollerContainerRef as never}
        aria-roledescription='slide'
        aria-label='rail-slide'
        className={clsx(
          styles['rail'],
          {
            [styles['rail--snap']]: snap,
          },
          className
        )}
        {...events}
      >
        {React.Children.map(children, (node, index) => {
          return (
            <li
              key={
                typeof node === 'object' && node && 'key' in node
                  ? node?.key
                  : index ?? index
              }
              className={clsx(styles['rail-item'], {
                [styles['rail-item--snap']]: snap,
                [styles['rail-item-peek']]: peek,
              })}
            >
              {node}
            </li>
          );
        })}
      </ul>
    </section>
  );
};

const Controls = (_props: RailControlsProps) => {
  const {
    isMobile,
    canScrollLeft,
    canScrollRight,
    activeDot,
    numberOfDots,
    jumpToDot,
    stepToDirection,
  } = useContext(RailContext);

  if (numberOfDots <= 1 || isMobile) {
    return null;
  }

  let dots = [];

  for (let i = 0; i < numberOfDots; i++) {
    dots.push(i);
  }

  return (
    <Flex justify='center'>
      <FlexBlock align='center'>
        <section
          aria-label='Slide controls'
          className={styles['rail-control-panel']}
        >
          <div className={styles['rail-control-left']}>
            {canScrollLeft && (
              <Button
                onClick={() => {
                  stepToDirection('left');
                }}
                iconOnly
                aria-label='Show previous slide'
                icon={<Icon icon={FarChevronLeft} />}
                variant='secondary'
              ></Button>
            )}
          </div>
          <div className={styles['rail-control-dots']}>
            {dots.map((dotNumber, index) => {
              let icon = <Icon icon={FasCircle} size='sm' color='inherit' />;

              if (activeDot === index) {
                icon = <Icon icon={FasCircle} color='info' />;
              }

              return (
                <Button
                  aria-label={`Show slide ${index + 1} of ${numberOfDots}`}
                  key={dotNumber}
                  icon={icon}
                  onClick={() => jumpToDot(index)}
                  iconOnly
                  variant='secondary'
                  className={styles['rail-button']}
                ></Button>
              );
            })}
          </div>
          <div className={styles['rail-control-right']}>
            {canScrollRight && (
              <Button
                onClick={() => {
                  stepToDirection('right');
                }}
                iconOnly
                aria-label='Show next slide'
                icon={<Icon icon={FarChevronRight} />}
                variant='secondary'
              ></Button>
            )}
          </div>
        </section>
      </FlexBlock>
    </Flex>
  );
};

const Title = (props: RailTitleProps) => {
  if (props.children) {
    return props.children;
  }

  if (props.title) {
    return (
      <Flex className={styles['rail-title-container']}>
        <Text
          as='h1'
          size='T400'
          weight='bold'
          className={styles['rail-title']}
          color='inherit'
        >
          {props.title}
        </Text>
        {props.viewAllLink && (
          <Anchor
            decoration='none'
            href={props.viewAllLink}
            color='inherit'
            className={styles['rail-title-view-all']}
          >
            <Text color='inherit'>View all</Text>
            <Icon icon={FalChevronRight} size='md' />
          </Anchor>
        )}
      </Flex>
    );
  }

  return null;
};

export { Scroller, Root, Controls, Title };
