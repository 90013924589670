import clsx from 'clsx';

import type { FC, ReactNode } from 'react';

import styles from './vertical-rhythm.module.scss';

export interface VerticalRhythmProps {
  verticalRhythm?: 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  spaceBetween?: 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  children: ReactNode;
}

export const VerticalRhythm: FC<VerticalRhythmProps> = ({
  verticalRhythm = 'md',
  spaceBetween = 'md',
  children,
}) => (
  <div
    data-testid='vertical-rhythm'
    className={clsx({
      [styles['vertical-rhythm']]: true,
      [styles['vertical-rhythm--vertical-padding-xs']]: verticalRhythm === 'xs',
      [styles['vertical-rhythm--vertical-padding-sm']]: verticalRhythm === 'sm',
      [styles['vertical-rhythm--vertical-padding-md']]: verticalRhythm === 'md',
      [styles['vertical-rhythm--vertical-padding-lg']]: verticalRhythm === 'lg',
      [styles['vertical-rhythm--vertical-padding-xl']]: verticalRhythm === 'xl',
      [styles['vertical-rhythm--vertical-padding-2xl']]:
        verticalRhythm === '2xl',
    })}
  >
    <div
      className={clsx({
        [styles['vertical-rhythm__inner']]: true,
        [styles['vertical-rhythm__inner--gap-xs']]: spaceBetween === 'xs',
        [styles['vertical-rhythm__inner--gap-sm']]: spaceBetween === 'sm',
        [styles['vertical-rhythm__inner--gap-md']]: spaceBetween === 'md',
        [styles['vertical-rhythm__inner--gap-lg']]: spaceBetween === 'lg',
        [styles['vertical-rhythm__inner--gap-xl']]: spaceBetween === 'xl',
        [styles['vertical-rhythm__inner--gap-2xl']]: spaceBetween === '2xl',
      })}
    >
      {children}
    </div>
  </div>
);
