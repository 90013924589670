import clsx from 'clsx';

import {
  Badge as ComponentLibraryBadge,
  BadgeProps as ComponentLibraryBadgeProps,
} from '@virginmediao2/momentum-nextjs/badge';
import { FC } from 'react';

import styles from './badge.module.scss';

export interface BadgeProps
  extends Omit<ComponentLibraryBadgeProps, 'variant'> {
  rounded?: boolean;
  variant?:
    | ComponentLibraryBadgeProps['variant']
    | 'countDown'
    | 'black'
    | 'offer';
}

export const Badge: FC<BadgeProps> = ({
  className,
  variant,
  rounded,
  ...props
}) => {
  return (
    <ComponentLibraryBadge
      {...props}
      className={clsx(
        variant === 'countDown' && styles['badge__countdown'],
        variant === 'black' && styles['badge__black'],
        variant === 'offer' && styles['badge__offer'],
        rounded && styles['badge__rounded']
      )}
      variant={variant as ComponentLibraryBadgeProps['variant']}
    />
  );
};
