import clsx from 'clsx';

import type { ComponentProps } from 'react';
import { type FC, Fragment } from 'react';

import { Text } from '../text/text';
import styles from './description-list.module.scss';

export type ListRatio = '1:1' | '1:2' | '1:3' | '2:3';

// Map the ratio to the CSS class name
enum ListRatioClassMap {
  '1:1' = '', // Default
  '1:2' = 'ratio-1-by-2',
  '1:3' = 'ratio-1-by-3',
  '2:3' = 'ratio-2-by-3',
}

export interface DescriptionListItem {
  [key: string]: string;
}

export type DescriptionListItems = DescriptionListItem[];

export interface DescriptionListProps extends ComponentProps<'dl'> {
  list: DescriptionListItems;
  ratio: ListRatio;
  stacked?: boolean;
}

export const DescriptionList: FC<DescriptionListProps> = ({
  list = [],
  ratio = '1:2',
  stacked = false,
  className,
  ...props
}) => {
  return (
    <dl
      data-testid='description-list'
      className={clsx(
        {
          [styles['dl']]: true,
          [styles['dl--stacked']]: stacked,
          [styles['dl--ratio-1-by-2']]: ratio === '1:2',
          [styles['dl--ratio-1-by-3']]: ratio === '1:3',
          [styles['dl--ratio-2-by-3']]: ratio === '2:3',
        },
        className
      )}
      {...props}
    >
      {list.map(({ term, description }, index) => {
        return (
          <Fragment key={index}>
            <Text as='dt' size='T300' weight={'bold'}>
              {term}
            </Text>
            <Text as='dd' size='T300'>
              {description}
            </Text>
          </Fragment>
        );
      })}
    </dl>
  );
};
