import clsx from 'clsx';

import type { ComponentProps, ReactNode } from 'react';
import { forwardRef } from 'react';

import styles from './anchor.module.scss';

export interface AnchorButtonProps
  extends Omit<ComponentProps<'button'>, 'ref'> {
  icon?: ReactNode;
  children: ReactNode;
  color?: 'light' | 'dark' | 'inherit';
  size?: 'sm' | 'inherit';
  expand?: boolean;
  className?: string;
  decoration?: 'none' | 'hover';
  isImage?: boolean;
  iconPosition?: 'start' | 'end';
  iconOnly?: boolean | 'md-down' | 'sm-down';
}

export const AnchorButton = forwardRef<HTMLButtonElement, AnchorButtonProps>(
  (
    {
      icon,
      children,
      color,
      className,
      decoration,
      expand,
      size,
      iconPosition,
      iconOnly,
      isImage,
      ...props
    },
    ref
  ) => (
    <button
      className={clsx(
        {
          [styles['anchor']]: true,
          [styles['anchor--color-inherit']]: color === 'inherit',
          [styles['anchor--color-light']]: color === 'light',
          [styles['anchor--color-dark']]: color === 'dark',
          [styles['anchor--size-sm']]: size === 'sm',
          [styles['anchor--size-inherit']]: size === 'inherit',
          [styles['anchor--is-image']]: isImage,
          [styles['anchor--expand']]: expand,
          [styles['anchor--decoration-hover']]: decoration === 'hover',
          [styles['anchor--decoration-none']]: decoration === 'none',
          [styles['anchor--icon-only']]: iconOnly === true,
          [styles['anchor--icon-only-md-down']]: iconOnly === 'md-down',
          [styles['anchor--icon-only-sm-down']]: iconOnly === 'sm-down',
          [styles['anchor--icon']]: icon,
          [styles['anchor--icon-position-start']]: iconPosition === 'start',
          [styles['anchor--icon-position-end']]: iconPosition === 'end',
        },
        className
      )}
      ref={ref}
      {...props}
    >
      <span className={styles['anchor__text']}>{children}</span>
      {icon && <span className={styles['anchor__icon']}>{icon}</span>}
    </button>
  )
);

AnchorButton.displayName = 'AnchorButton';
