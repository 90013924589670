import { SearchResult } from 'minisearch';

import { Button } from '@virginmediao2/momentum-nextjs/button';
import { FormInput } from '@virginmediao2/momentum-nextjs/forms';
import { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { getCountryCharges, searchCountryCharges } from '../actions';
import { IntlCallRatesContent, SearchForm } from '../types';
import styles from './search-box.module.css';

export interface SearchFormProps {
  setSelectedCountry: (country: SearchResult | undefined) => void;
  setSearchResults: (results: Array<SearchResult>) => void;
  setSelectedData: (country: IntlCallRatesContent | undefined) => void;
}

export const SearchBox: FC<SearchFormProps> = ({
  setSearchResults,
  setSelectedCountry,
  setSelectedData,
}) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<SearchForm>();

  const onSubmit: SubmitHandler<SearchForm> = useCallback(
    async (form) => {
      const results = await searchCountryCharges(form.search);
      const sortedResults = results!.sort((a, b) => b.score - a.score);

      if (sortedResults.length === 0) {
        setSelectedCountry(undefined);
      } else {
        const data = await getCountryCharges(sortedResults[0].id);
        setSelectedData(data);
      }
      setSearchResults(sortedResults);
    },
    [setSelectedCountry, setSearchResults, setSelectedData]
  );

  return (
    <form
      data-testid='search-box'
      autoComplete={'off'}
      onSubmit={handleSubmit(onSubmit)}
      className={styles['searchbox']}
    >
      <div className={styles['searchbox__input']}>
        <FormInput
          id={'search'}
          placeholder='eg. Spain'
          autoComplete={'off'}
          disabled={isSubmitting}
          {...register('search', { required: true, minLength: 2 })}
        />
      </div>
      <div className={styles['searchbox__submit']}>
        <Button
          data-testid='search-country-button'
          variant={'primary'}
          decoration={'outline'}
          type='submit'
          disabled={isSubmitting}
        >
          Find
        </Button>
      </div>
    </form>
  );
};
