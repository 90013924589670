export const toPrice = (value: number | string): string => {
  const numericValue = typeof value === 'string' ? Number(value) : value;

  if (isNaN(numericValue)) {
    return value.toString();
  }

  if (numericValue < 1) {
    return `${numericValue * 100}p`;
  }

  if (numericValue % 1 === 0) {
    return `£${numericValue}`;
  }

  return `£${numericValue.toFixed(2)}`;
};
