'use client';

import clsx from 'clsx';

import ReactFocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';

import { Anchor, AnchorProps } from '../anchor';
import { Button, type ButtonProps } from '../button';
import { useModal } from './modal.context';
import styles from './modal.module.scss';

const ModalOpenButton = ({ children, ...props }: ButtonProps) => {
  const { open } = useModal();

  return (
    <Button type='button' {...props} onClick={open}>
      {children}
    </Button>
  );
};

const ModalOpenLink = ({ children, ...props }: Omit<AnchorProps, 'href'>) => {
  const { open } = useModal();
  return (
    <Anchor href='#' {...props} onClick={open}>
      {children}
    </Anchor>
  );
};

const Modal = ({ children }: React.PropsWithChildren) => {
  const { isOpen, close } = useModal();

  return isOpen ? (
    <ReactFocusLock returnFocus>
      <RemoveScroll>
        <div className={styles['modal__wrapper']}>
          <button
            data-testid='modal-backdrop'
            className={styles['backdrop']}
            onClick={close}
            type='button'
          />
          <div
            className={clsx({
              [styles['modal']]: true,
            })}
          >
            {children}
          </div>
        </div>
      </RemoveScroll>
    </ReactFocusLock>
  ) : null;
};

const ModalHeader = ({ children }: React.PropsWithChildren) => (
  <div className={styles['modal__head']}>{children}</div>
);

const ModalTitle = ({ children }: React.PropsWithChildren) => (
  <div className={styles['modal__title']}>{children}</div>
);

const ModalCloseButton = ({
  float = false,
  children,
}: { float?: boolean } & React.PropsWithChildren) => {
  const { close } = useModal();
  return (
    <button
      className={clsx({
        [styles['modal__close']]: true,
        [styles['modal__close--float']]: float,
      })}
      type='button'
      onClick={close}
      aria-label='Close modal'
    >
      {children}
    </button>
  );
};

const ModalDismissButton = ({ children, ...props }: ButtonProps) => {
  const { close } = useModal();
  return (
    <Button {...props} onClick={close}>
      {children}
    </Button>
  );
};

const ModalBody = ({
  padding = true,
  children,
}: { padding?: boolean } & React.PropsWithChildren) => (
  <div
    className={clsx({
      [styles['modal__body']]: true,
      [styles['modal__body--no-padding']]: !padding,
    })}
  >
    {children}
  </div>
);

const ModalFooter = ({ children }: React.PropsWithChildren) => (
  <div className={styles['modal__foot']}>{children}</div>
);

export {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalOpenButton,
  ModalOpenLink,
  ModalDismissButton,
};
