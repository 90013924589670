import clsx from 'clsx';

import { Children, ReactNode } from 'react';

import { SideNavigationTrigger } from '@/components/momentum/factory/mega-menu/side-navigation/side-navigation-trigger';
import { handleClickType } from '@/components/storyblok/bloks/dynamo-megamenu/dynamo-megamenu.blok';

import styles from './utils.module.scss';

export interface MegamenuUtilsProps {
  children: ReactNode;
}

export function MegamenuUtils({ children }: MegamenuUtilsProps) {
  return (
    <ul className={styles['mega-menu-utils']} data-megamenu-nav>
      {Children.map(children, (child) => (
        <li className={styles['mega-menu-util']}>{child}</li>
      ))}
      <li
        className={clsx(
          styles['mega-menu-util'],
          styles['mega-menu-util-hide']
        )}
      >
        <SideNavigationTrigger />
      </li>
    </ul>
  );
}
