import { ISbRichtext } from '@storyblok/js';
import { ISbComponentType } from 'storyblok-js-client';

import { ISbStoryData } from '@storyblok/react/rsc';

import { BLOK } from '@/components/storyblok/bloks';

export type SearchResult = {
  id: number;
  name: string;
  score: number;
};

export type SearchForm = {
  search?: string;
  story_id?: number;
};

export type IntlCallRatesContent = ISbStoryData<IntlCallRatesBlok>;

export type IntlCallRatesBlok =
  ISbComponentType<BLOK.O2_INTERNATIONAL_CHARGES> & {
    country: string;
    boltons: Array<string>;
    data_points: Array<string>;
    bullets: ISbRichtext;

    eurozone: boolean;

    payg_zone_info?: ISbRichtext;
    paym_zone_info?: ISbRichtext;
    paym_message?: string;
    payg_message?: string;
    paym_freeform_message?: string;
    payg_freeform_message?: string;

    payg_std_inbound_price?: number;
    payg_std_inbound_free: boolean;
    payg_std_inbound_in_allowance: boolean;

    payg_std_outbound_price?: number;
    payg_std_outbound_free: boolean;
    payg_std_outbound_in_allowance: boolean;

    payg_sms_price?: number;
    payg_sms_free: boolean;
    payg_sms_in_allowance: boolean;

    payg_data_price?: number;
    payg_data_free: boolean;
    payg_data_in_allowance: boolean;

    paym_std_inbound_price?: number;
    paym_std_inbound_free: boolean;
    paym_std_inbound_in_allowance: boolean;

    paym_std_outbound_price?: number;
    paym_std_outbound_free: boolean;
    paym_std_outbound_in_allowance: boolean;

    paym_sms_price?: number;
    paym_sms_free: boolean;
    paym_sms_in_allowance: boolean;

    paym_data_price?: number;
    paym_data_free: boolean;
    paym_data_in_allowance: boolean;

    paym_roaming_bundles: Array<{
      _uid: string;
      name: string;
      price: number;
    }>;

    paym_bundle_prompt?: string;
  };

export enum RatesType {
  PAYG = 'PAYG',
  PAYM = 'PAYM',
}
