'use client';

import { createContext, useCallback, useContext, useState } from 'react';

type ContextType = {
  isOpen: boolean;
  close: () => void;
  open: () => void;
};

const Context = createContext<ContextType>({
  isOpen: false,
  close: () => {},
  open: () => {},
});

interface Props {
  defaultIsOpen?: boolean;
  children: React.ReactNode;
  onClose?: () => void;
}

function ModalProvider({
  children,
  defaultIsOpen = false,
  onClose = () => {},
}: Props) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const close = useCallback(() => {
    setIsOpen(false);

    onClose?.();
  }, [setIsOpen, onClose]);

  const open = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  return (
    <Context.Provider value={{ isOpen, close, open }}>
      {children}
    </Context.Provider>
  );
}

function useModal() {
  const context = useContext(Context);
  if (!context) {
    throw new Error('Missing `ModalProvider` provider');
  }
  return context;
}

export { ModalProvider, useModal };
