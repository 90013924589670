import clsx from 'clsx';

import { FC } from 'react';

import styles from './step-indicator.module.scss';

export interface StepIndicatorProps {
  numberOfSteps: number;
  activeStep: number;
  label?: string;
}

export const StepIndicator: FC<StepIndicatorProps> = ({
  numberOfSteps,
  activeStep,
  label,
}) => {
  if (numberOfSteps < 1 || activeStep > numberOfSteps) {
    return;
  }

  const defaultLabel = `Step ${activeStep} of ${numberOfSteps}`;

  return (
    <div
      className={styles['step-indicator']}
      id='step-indicator'
      role='progressbar'
      aria-label='Progress'
      aria-valuemin={0}
      tabIndex={0}
      data-testid='step-indicator'
      aria-valuenow={activeStep}
      aria-valuetext={label ?? defaultLabel}
      aria-valuemax={numberOfSteps}
    >
      <ol className={styles['step-indicator__steps']}>
        {Array.from(Array(numberOfSteps).keys()).map((_, step) => (
          <li
            key={step}
            className={clsx({
              [styles['step-indicator__step']]: true,
              [styles['step-indicator__step--is-complete']]: activeStep > step,
              [styles['step-indicator__step--is-active']]: activeStep === step,
            })}
            aria-current={activeStep === step ? 'step' : undefined}
          >
            <div className={styles['step-indicator__step-progress']} />
          </li>
        ))}
      </ol>
      <label htmlFor='step-indicator'>
        <p className={styles['step-indicator__label']}>
          {label ?? defaultLabel}
        </p>
      </label>
    </div>
  );
};
