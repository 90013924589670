import { useId } from 'react';

export type FieldIds = {
  id: string;
  labelId: string;
  descriptionId: string;
  errorId: string;
  describedBy: string | undefined;
};

type FieldIdsOptions = {
  id?: string;
  hasDescription?: boolean;
  isError?: boolean;
};

function generateFieldId(id: string, suffix: string): string {
  return `${id}-${suffix}`;
}

export function useFieldIds({
  id,
  hasDescription = false,
  isError = false,
}: FieldIdsOptions): FieldIds {
  const uid = useId();
  const _id = id ?? uid;

  const labelId = generateFieldId(_id, 'label');
  const descriptionId = generateFieldId(_id, 'description');
  const errorId = generateFieldId(_id, 'error');

  const describedBy =
    [isError && errorId, hasDescription && descriptionId]
      .filter(Boolean)
      .join(' ') || undefined;

  return {
    id: _id,
    labelId,
    descriptionId,
    errorId,
    describedBy,
  };
}
