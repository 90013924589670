import clsx from 'clsx';

import React, { ComponentProps } from 'react';

import styles from './form-item.module.scss';

export interface FormItemDescriptionProps
  extends Omit<ComponentProps<'div'>, 'id' | 'className'> {
  id: string;
  children: React.ReactNode;
  className?: string;
}

export const FormItemDescription: React.FC<FormItemDescriptionProps> = ({
  id,
  children,
  className,
  ...props
}) => {
  return (
    <div
      id={id}
      className={clsx(styles['form-item__description'], className)}
      {...props}
    >
      {children}
    </div>
  );
};
