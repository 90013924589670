import { SbImage } from '../../types/storyblok.interface';

export interface CoercedImage {
  src: string;
  alt: string;
  width: number;
  height: number;
  is_external: boolean;
  is_private: boolean;
  is_svg: boolean;
}

const regex = /https:\/\/a\.storyblok\.com\/f\/\d+\/(\d+)x(\d+)\//;

export const coerceImage = (image?: SbImage): CoercedImage | null => {
  if (!image?.filename) {
    return null;
  }

  const pattern = image.filename.match(regex);
  if (!pattern) {
    console.error(`ngStoryblok: INVALID_IMG_FILENAME`);
    return null;
  }

  const [, width, height] = pattern;

  return {
    src: image.filename,
    alt: image.alt,
    width: +width,
    height: +height,
    is_external: image.is_external_url,
    is_private: image.is_private,
    is_svg: image.filename.includes('.svg'),
  };
};
export const useImage = coerceImage;
