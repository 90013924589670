'use client';

import clsx from 'clsx';

import type { ComponentProps, FC } from 'react';

import styles from './horizontal-rule.module.scss';

export interface HorizontalRuleProps extends ComponentProps<'hr'> {
  spacing: 'none' | 'xs' | 'sm' | 'md' | 'lg';
}

export const HorizontalRule: FC<HorizontalRuleProps> = ({
  spacing,
  ...props
}) => (
  <hr
    className={clsx({
      [styles['horizontal-rule']]: true,
      [styles['horizontal-rule--margin-none']]: spacing === 'none',
      [styles['horizontal-rule--margin-xs']]: spacing === 'xs',
      [styles['horizontal-rule--margin-sm']]: spacing === 'sm',
      [styles['horizontal-rule--margin-md']]: spacing === 'md',
      [styles['horizontal-rule--margin-lg']]: spacing === 'lg',
    })}
    {...props}
  />
);
