'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import React, { ComponentProps } from 'react';
import { useForm } from 'react-hook-form';

import { FalMagnifyingGlass } from '@virginmediao2/momentum-icons/icons/fal';

import { Button } from '@/components/momentum/components/button';
import { Icon } from '@/components/momentum/components/icon';

import { FormInput } from '../form-input';
import styles from './form-search-input.module.scss';

const searchFormSchema = z.object({
  search: z.string(),
});

export type SearchFormData = z.infer<typeof searchFormSchema>;

export interface FormSearchInputProps
  extends Omit<ComponentProps<'input'>, 'placeholder'> {
  placeholder: string;
  onSubmit: (data: any) => void;
}

export const FormSearchInput: React.FC<FormSearchInputProps> = ({
  placeholder,
  onSubmit,
}) => {
  const { handleSubmit, register } = useForm<SearchFormData>({
    resolver: zodResolver(searchFormSchema),
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    shouldUseNativeValidation: false,
    defaultValues: {
      search: '',
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className={styles['form-search-input']}>
        <FormInput
          id='search'
          {...register('search')}
          placeholder={placeholder}
          type='search'
        />
        <Button
          type='submit'
          aria-label='Search'
          className={styles['form-search-input-button']}
          iconOnly
          icon={
            <Icon
              icon={FalMagnifyingGlass}
              size={'md'}
              color='info'
              ratio={1}
            />
          }
        />
      </div>
    </form>
  );
};
