import clsx from 'clsx';

import React from 'react';
import { FieldError } from 'react-hook-form';

import { FarXmark } from '@virginmediao2/momentum-icons/icons/far';

import { Icon } from '../../icon';
import { FieldIds } from '../hooks/useFieldIds';
import { FormItemDescription } from './form-item-description';
import { FormItemError } from './form-item-error';
import { FormItemLabel } from './form-item-label';
import styles from './form-item.module.scss';

export interface FormItemProps {
  fieldIds: FieldIds;
  label?: React.ReactNode;
  description?: React.ReactNode;
  error?: FieldError;
  hideLabels?: boolean;
  marginBottom?: boolean;
  children: React.ReactNode;
}

export const FormItem: React.FC<FormItemProps> = ({
  fieldIds,
  label,
  description,
  error,
  hideLabels,
  marginBottom,
  children,
}) => {
  return (
    <div
      className={clsx(styles['form-item'], {
        [styles['form-item--has-hidden-labels']]: hideLabels,
        [styles['form-item--margin-bottom']]: marginBottom,
      })}
      data-testid={`form-item--${fieldIds.id}`}
    >
      {!hideLabels && label && (
        <FormItemLabel
          id={fieldIds.labelId}
          htmlFor={fieldIds.id}
          data-testid={`form-item-label--${fieldIds.id}`}
        >
          {label}
        </FormItemLabel>
      )}
      {description && (
        <FormItemDescription
          id={fieldIds.descriptionId}
          data-testid={`form-item-description--${fieldIds.id}`}
        >
          {description}
        </FormItemDescription>
      )}
      <div
        className={clsx(styles['form-item__control'], {
          [styles['form-item__control--no-top-padding']]: hideLabels,
        })}
        data-testid={`form-item-control--${fieldIds.id}`}
      >
        {children}
      </div>
      {error?.message && (
        <FormItemError
          id={fieldIds.errorId}
          data-testid={`form-item-error--${fieldIds.id}`}
        >
          <Icon icon={FarXmark} />
          <div className={styles['form-item__error-message']}>
            <p>{error.message}</p>
          </div>
        </FormItemError>
      )}
    </div>
  );
};
