'use client';

import clsx from 'clsx';

import { ComponentProps, FC, ReactNode, useCallback, useRef } from 'react';

import styles from './media-banner.module.scss';

export interface MediaBannerProps extends ComponentProps<'div'> {
  image: ReactNode;
  icon?: ReactNode;
  variant: 'curved' | 'flat';
  children: ReactNode;
  contentWidth?: 'full' | 'narrow';
}

export const MediaBanner: FC<MediaBannerProps> = ({
  image,
  icon,
  variant,
  children,
  contentWidth,
  className,
  ...props
}) => {
  const currentMediaBanner = useRef<HTMLDivElement>(null);

  const scrollTo = useCallback(() => {
    const current = currentMediaBanner?.current;
    const next = current?.nextElementSibling as HTMLDivElement;
    if (next) {
      next.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentMediaBanner]);

  return (
    <div
      data-testid='media-banner'
      ref={currentMediaBanner}
      className={clsx(
        {
          [styles['media-banner']]: true,
          [styles['media-banner--curved']]: variant === 'curved',
          [styles['media-banner--flat']]: variant === 'flat',
        },
        className
      )}
      {...props}
    >
      {image && (
        <div
          className={styles['media-banner__image']}
          data-testid='media-banner-image'
        >
          {image}
        </div>
      )}

      <div className={styles['media-banner__container']}>
        <div
          className={clsx(styles['media-banner__inner'], {
            [styles['media-banner__inner--full']]: contentWidth === 'full',
          })}
        >
          <div className={styles['media-banner__content']}>{children}</div>
        </div>
      </div>

      {icon && (
        <div className={styles['media-banner__icon']}>
          <button
            onClick={scrollTo}
            className={styles['media-banner__icon-circle']}
            type='button'
            aria-hidden
            data-testid='media-banner-icon-button'
          >
            {icon}
          </button>
        </div>
      )}
    </div>
  );
};
