'use client';

import { useRouter } from 'next/navigation';

import { FormSearchInput } from '@/components/momentum/components/forms/form-search-input';

export const HelpSearchInput = ({ placeholder }: { placeholder?: string }) => {
  const router = useRouter();

  const onSubmit = (data: { search: string }) => {
    const searchQuery = encodeURIComponent(data.search);
    router.push(`https://www.o2.co.uk/searchresult?query=${searchQuery}`);
  };

  return (
    <FormSearchInput
      placeholder={placeholder ?? 'Search help and support'}
      onSubmit={onSubmit}
    />
  );
};
