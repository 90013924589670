import clsx from 'clsx';

import React, { ComponentProps } from 'react';

import styles from './form-item.module.scss';

export interface FormItemLabelProps
  extends Omit<ComponentProps<'label'>, 'id' | 'htmlFor' | 'className'> {
  id: string;
  htmlFor: string;
  children: React.ReactNode;
  className?: string;
}

export const FormItemLabel: React.FC<FormItemLabelProps> = ({
  id,
  htmlFor,
  children,
  className,
  ...props
}) => {
  return (
    <label
      id={id}
      htmlFor={htmlFor}
      className={clsx(styles['form-item__label'], className)}
      {...props}
    >
      {children}
    </label>
  );
};
