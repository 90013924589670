'use client';

import Script from 'next/script';
import { FC } from 'react';

import styles from './overpayment-calculator.module.scss';

export interface OverPaymentCalculatorProps {}

export const OverPaymentCalculator: FC<OverPaymentCalculatorProps> = () => {
  return (
    <div>
      <Script src='/scripts/overpayment/index.js' />
      <link href='/styles/overpayment/index-c37239f9.css' rel='stylesheet' />
      <div
        id='ooccApp'
        data-testid='ooccApp'
        className={styles['ooccApp']}
      ></div>
      <Script src='https://kit.fontawesome.com/440d57507a.js' />
    </div>
  );
};
