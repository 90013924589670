'use client';

import clsx from 'clsx';

import { ComponentProps, FC, useId, useState } from 'react';

import { FasChevronDown } from '@virginmediao2/momentum-icons/icons/fas';

import { Icon, StoryblokIcon } from '../icon';
import styles from './accordion.module.scss';

export interface AccordionProps
  extends Omit<ComponentProps<'div'>, 'className' | 'onClick'> {
  children?: React.ReactNode;
  title: string;
  expanded?: boolean;
  icon?: StoryblokIcon;
  renderWhenCollapsed?: boolean;
  titleProps?: Omit<ComponentProps<'button'>, 'className' | 'onClick'>;
  bodyProps?: Omit<ComponentProps<'div'>, 'className' | 'onClick'>;
  bodyContainerProps?: Omit<ComponentProps<'div'>, 'className' | 'onClick'>;
}

export const Accordion: FC<AccordionProps> = ({
  children,
  title,
  expanded,
  icon,
  renderWhenCollapsed = true,
  titleProps,
  bodyProps,
  bodyContainerProps,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const id = useId();

  return (
    <div
      id={id}
      className={clsx({
        [styles['accordion']]: true,
        [styles['accordion--expanded']]: isExpanded,
      })}
      {...props}
    >
      <button
        data-testid='accordion__trigger'
        className={styles['accordion__trigger']}
        aria-expanded={isExpanded}
        aria-controls={id}
        onClick={() => setIsExpanded(!isExpanded)}
        {...titleProps}
      >
        <span className={styles['accordion__title-wrapper']}>
          {icon && (
            <span className={styles['accordion__icon']}>
              <Icon icon={icon} size='md' />
            </span>
          )}
          <span className={styles['accordion__title']}>{title}</span>
          <span className={styles['accordion__indicator']}>
            <Icon icon={FasChevronDown} size='md' />
          </span>
        </span>
      </button>
      <div
        data-testid='accordion__panel'
        role='region'
        className={styles['accordion__panel']}
        aria-labelledby={id}
        {...bodyContainerProps}
      >
        <div
          data-testid='accordion__content'
          className={styles['accordion__content']}
          {...bodyProps}
        >
          {!renderWhenCollapsed && expanded && children}
          {renderWhenCollapsed && children}
        </div>
      </div>
    </div>
  );
};
