import { useMemo } from 'react';

import type { Icon } from '@virginmediao2/momentum-icons';

import type { StoryblokIcon } from './theme-icons.interface';

export interface IconObject {
  type: 'icon' | 'url';
  icon?: Icon;
  url?: string;
}

export const useIcon = (icon: string | Icon | StoryblokIcon): IconObject => {
  return useMemo(() => {
    // Icon object
    if (typeof icon === 'object' && icon.hasOwnProperty('xmlns')) {
      return {
        type: 'icon',
        icon: icon as Icon,
      };
    }

    if (typeof icon === 'object' && icon.hasOwnProperty('iconSet')) {
      const _icon = icon as StoryblokIcon;
      const value = _icon.dimension_value || _icon.value;
      const path = value.replace(
        'https://a.storyblok.com',
        'https://a2.storyblok.com'
      );
      return {
        type: 'url',
        url: `url(${path})`,
      };
    }

    throw new Error('Icon is not valid');
  }, [icon]);
};
