import { SearchResult } from 'minisearch';

import { Tab, Tabs } from '@virginmediao2/momentum-nextjs/tabs';
import { Text } from '@virginmediao2/momentum-nextjs/text';
import { FC, useCallback } from 'react';

export interface SearchResultsProps {
  searchResults: Array<SearchResult>;
  selectedCountry: SearchResult | undefined;
  setSelectedCountry: (country: SearchResult | undefined) => void;
}

export const SearchResults: FC<SearchResultsProps> = ({
  searchResults,
  setSelectedCountry,
  selectedCountry,
}) => {
  const handleClick = useCallback(
    async (result: SearchResult) => {
      setSelectedCountry(result);
    },
    [setSelectedCountry]
  );

  if (!searchResults.length) {
    return (
      <Text color={'error'}>
        Sorry, we couldn&apos;t find any matching countries.
      </Text>
    );
  }

  return (
    <>
      <Tabs
        controls={searchResults.length > 2}
        breakout={searchResults.length > 2}
      >
        {searchResults.map((result) => (
          <Tab
            key={result.id}
            active={selectedCountry?.id === result.id}
            onClick={() => handleClick(result)}
          >
            {result.name}
          </Tab>
        ))}
      </Tabs>
    </>
  );
};
