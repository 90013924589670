'use client';

import clsx from 'clsx';

import { ElementType, PropsWithChildren, useEffect, useRef } from 'react';

import styles from './scrollable.module.scss';

interface ScrollableProps extends PropsWithChildren {
  as?: 'div' | ElementType;
  className?: string;
  scrollbar?: boolean;
  clamp?: 'start' | 'end';
}

export function Scrollable({
  as: Component = 'div',
  children,
  className = '',
  scrollbar = true,
  clamp = 'start',
  ...props
}: ScrollableProps) {
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    const handleResizeOnMount = () => {
      const element = ref.current;
      if (element && clamp === 'end') {
        element.scrollTo({ left: element.scrollWidth });
      }
    };

    handleResizeOnMount();
    window.addEventListener('resize', handleResizeOnMount);
    return () => {
      window.removeEventListener('resize', handleResizeOnMount);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- `onMount`
  }, []);

  return (
    <Component
      ref={ref}
      className={clsx(
        {
          [styles['scrollable']]: true,
          [styles['scrollable--no-scrollbar']]: !scrollbar,
        },
        className
      )}
      {...(!scrollbar ? { tabIndex: 0 } : undefined)}
      {...props}
    >
      {children}
    </Component>
  );
}
