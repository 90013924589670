import clsx from 'clsx';

import { FC, ReactNode } from 'react';

import styles from './l1-navigation-item.module.scss';

export interface L1CategoryProps {
  title: ReactNode;
  children: ReactNode;
  uid: string;
}

export const L1NavigationCategory: FC<L1CategoryProps> = ({
  title,
  children,
  uid,
}) => (
  <div className='l1-navigation-category' data-category-uid={uid}>
    <div
      className={clsx(
        styles['mega-menu-navigation-item__link'],
        'mega-menu-navigation-item__link_after'
      )}
    >
      {title}
    </div>
    <div
      className={styles['mega-menu-navigation-item__content']}
      data-category-content
    >
      {children}
    </div>
  </div>
);
