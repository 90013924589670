'use client';

import { isRichTextEmpty } from '@storyblok/js';

import { FC, useState } from 'react';

import { Button } from '@/components/momentum/components/button';
import { Content } from '@/components/momentum/components/content';
import { HorizontalRule } from '@/components/momentum/components/horizontal-rule';
import { Text } from '@/components/momentum/components/text';
import { IStoryblokComponent } from '@/components/storyblok/renderer';
import { RenderRichText } from '@/components/storyblok/utils/render-richtext';

import { IntlCallRatesBlok, RatesType } from '../types';
import styles from './CountryRates.module.css';
import { PayAsYouGo } from './PayAsYouGo';
import { PayMonthly } from './PayMonthly';
import { SelectDatapoint } from './SelectDatapoint';

export interface CountryRatesProps {
  blok: IntlCallRatesBlok;
  StoryblokComponent: IStoryblokComponent;
}

export const CountryRates: FC<CountryRatesProps> = ({
  blok,
  StoryblokComponent,
}) => {
  const [rateTypeToShow, setRateTypeToShow] = useState<RatesType>(
    RatesType.PAYM
  );

  if (!blok) return null;

  return (
    <div className={styles['country']}>
      <div className={styles['country__header']}>
        {blok.paym_freeform_message && rateTypeToShow === RatesType.PAYM && (
          <div className={styles['country__rates--pay-ff-msg']}>
            <Text>{blok.paym_freeform_message}</Text>
          </div>
        )}

        {blok.payg_freeform_message && rateTypeToShow === RatesType.PAYG && (
          <div className={styles['country__rates--pay-ff-msg']}>
            <Text>{blok.payg_freeform_message}</Text>
          </div>
        )}

        {(blok.paym_freeform_message || blok.payg_freeform_message) && (
          <HorizontalRule spacing='sm' />
        )}

        <Text as={'h2'} size={'T400'} weight='medium' id={'ratestype-question'}>
          How much will it cost to use my phone?
        </Text>

        <div className={styles['country__payment--toggles']}>
          <Button
            aria-describedby={'ratestype-question'}
            data-testid='search-country-button'
            variant={'primary'}
            decoration={rateTypeToShow === RatesType.PAYM ? 'solid' : 'outline'}
            onClick={() => setRateTypeToShow(RatesType.PAYM)}
            role={'switch'}
            aria-checked={rateTypeToShow === RatesType.PAYM}
          >
            Pay Monthly
          </Button>
          <Button
            aria-describedby={'ratestype-question'}
            data-testid='search-country-button'
            variant={'primary'}
            decoration={rateTypeToShow === RatesType.PAYG ? 'solid' : 'outline'}
            role={'switch'}
            aria-checked={rateTypeToShow === RatesType.PAYM}
            onClick={() => setRateTypeToShow(RatesType.PAYG)}
          >
            Pay As You Go
          </Button>
        </div>
      </div>

      {rateTypeToShow === RatesType.PAYM && (
        <PayMonthly blok={blok} StoryblokComponent={StoryblokComponent} />
      )}

      {rateTypeToShow === RatesType.PAYG && (
        <PayAsYouGo blok={blok} StoryblokComponent={StoryblokComponent} />
      )}

      {blok.bullets && !isRichTextEmpty(blok.bullets) && (
        <>
          <div className={styles['country__info']}>
            <Text as={'h2'} size={'T500'} weight='medium' alignment='center'>
              Things to note
            </Text>

            <HorizontalRule spacing='sm' />

            <div className={styles['country__datapoints']}>
              {blok.data_points.map((point) => (
                <SelectDatapoint datapoint={point} key={point} />
              ))}
            </div>

            <Content>
              <RenderRichText
                document={blok.bullets}
                StoryblokComponent={StoryblokComponent}
              />
            </Content>
          </div>
          <HorizontalRule spacing='sm' />
        </>
      )}
    </div>
  );
};
